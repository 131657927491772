import React from 'react';
import * as S from './RedditSourcePost.styled';
import IRedditSourcePost from './RedditSourcePost.type';

const RedditSourcePost = ({
    title,
    votesCount,
    commentsCount,
    imageUrl,
}: IRedditSourcePost) => {
    return (
        <S.RedditSourcePost>
            <S.RedditSourceTitle>{title}</S.RedditSourceTitle>
            <S.RedditSourceMeta>
                <S.RedditSourceItem>
                    <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.500043 8H9.50004C9.59116 7.99969 9.68048 7.97284 9.75838 7.92233C9.83628 7.87181 9.89981 7.79955 9.94214 7.71333C9.98447 7.6271 10.004 7.53017 9.99859 7.43297C9.9932 7.33577 9.96311 7.24198 9.91154 7.1617L5.41154 0.215985C5.22504 -0.0719951 4.77604 -0.0719951 4.58904 0.215985L0.0890427 7.1617C0.0369551 7.24182 0.0064098 7.33565 0.000725322 7.43301C-0.00495916 7.53037 0.0144347 7.62754 0.0567997 7.71395C0.0991647 7.80036 0.162881 7.87271 0.241025 7.92314C0.319169 7.97357 0.408753 8.00015 0.500043 8Z"
                            fill="#656766"
                        ></path>
                    </svg>
                    {votesCount}
                </S.RedditSourceItem>
                <S.RedditSourceItem>
                    <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 0.5C3.1002 0.5 0.75 2.37031 0.75 4.67857C0.75 5.675 1.18887 6.58705 1.91895 7.30424C1.6626 8.31674 0.805371 9.21875 0.795117 9.22879C0.75 9.275 0.737695 9.3433 0.764355 9.40357C0.791016 9.46384 0.848437 9.5 0.914062 9.5C2.27373 9.5 3.29297 8.86116 3.79746 8.46741C4.46807 8.71451 5.2125 8.85714 6 8.85714C8.8998 8.85714 11.25 6.98683 11.25 4.67857C11.25 2.37031 8.8998 0.5 6 0.5Z"
                            fill="#828584"
                        ></path>
                    </svg>
                    {commentsCount}
                </S.RedditSourceItem>
            </S.RedditSourceMeta>
            <S.RedditSourceImage src={imageUrl} />
        </S.RedditSourcePost>
    );
};

export default RedditSourcePost;
