import React from 'react';
import * as S from './RSSSourcePost.styled';
import IRSSSourcePost from './RSSSourcePost.type';

const RSSSourcePost = ({ title, author, date }: IRSSSourcePost) => {
    return (
        <S.RSSSourcePost>
            <S.RSSSourceTitle>{title} </S.RSSSourceTitle>
            {/* <S.RSSSourceContent>{content}</S.RSSSourceContent> */}
            <S.RSSSourceMeta>
                <S.RSSSourceItem>
                    <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.21423 5.00042C6.59521 5.00042 7.71444 3.88118 7.71444 2.50021C7.71444 1.11923 6.59521 0 5.21423 0C3.83326 0 2.71402 1.11923 2.71402 2.50021C2.71402 3.88118 3.83326 5.00042 5.21423 5.00042ZM4.32158 5.938C2.39759 5.938 0.838867 7.49672 0.838867 9.42071C0.838867 9.74105 1.09865 10.0008 1.41899 10.0008H9.00947C9.32981 10.0008 9.5896 9.74105 9.5896 9.42071C9.5896 7.49672 8.03088 5.938 6.10689 5.938H4.32158Z"
                            fill="#656766"
                        ></path>
                    </svg>
                    {author}
                </S.RSSSourceItem>
                <S.RSSSourceItem>
                    <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2.74805 0.777588C2.74805 0.702931 2.71839 0.631332 2.6656 0.578541C2.61281 0.525751 2.54121 0.496094 2.46655 0.496094C2.3919 0.496094 2.3203 0.525751 2.26751 0.578541C2.21472 0.631332 2.18506 0.702931 2.18506 0.777588V1.05908H1.62207C1.32344 1.05908 1.03705 1.17771 0.825885 1.38887C0.614723 1.60003 0.496094 1.88643 0.496094 2.18506L0.496094 2.74805H9.50391V2.18506C9.50391 1.88643 9.38528 1.60003 9.17412 1.38887C8.96295 1.17771 8.67656 1.05908 8.37793 1.05908H7.81494V0.777588C7.81494 0.702931 7.78528 0.631332 7.73249 0.578541C7.6797 0.525751 7.6081 0.496094 7.53345 0.496094C7.45879 0.496094 7.38719 0.525751 7.3344 0.578541C7.28161 0.631332 7.25195 0.702931 7.25195 0.777588V1.05908H2.74805V0.777588ZM9.50391 8.37793V3.31104H0.496094V8.37793C0.496094 8.67656 0.614723 8.96295 0.825885 9.17412C1.03705 9.38528 1.32344 9.50391 1.62207 9.50391H8.37793C8.67656 9.50391 8.96295 9.38528 9.17412 9.17412C9.38528 8.96295 9.50391 8.67656 9.50391 8.37793Z"
                            fill="#656766"
                        ></path>
                    </svg>
                    {date}
                </S.RSSSourceItem>
            </S.RSSSourceMeta>
        </S.RSSSourcePost>
    );
};

export default RSSSourcePost;
