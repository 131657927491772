import React, { useState } from 'react';
import { sendFormData } from '../../../api';
import { useForm } from '../../../hooks/useForm';

import { Form } from '../../Form';
import { Input } from '../../Input';
import { Textarea } from '../../Textarea';

import {
    DefaultValues,
    ContactFormFields,
    ContactSchemaKeys,
} from './constants';
import * as S from './ContactForm.styled';
import { ContactFormShape } from './types';

const ContactForm: React.FC = ({ emails }: any) => {
    const [message, setMessage] = useState('');
    const [disable, setDisable] = useState(false);

    const { formMethods, isSubmitting, handleSubmit } = useForm<
        keyof ContactFormShape,
        ContactFormShape
    >({
        schemaKeys: ContactSchemaKeys,
        defaultValues: DefaultValues,
    });

    const submitForm = async (data: any, event: any) => {
        event.preventDefault();
        setDisable(true);
        emails.forEach((item: any) => {
            console.log(item.email);
        });

        try {
            setMessage('Message was sent.');
            sendFormData(data);
        } catch (event) {
            setMessage('Sorry, something went wrong.');
        }
        console.log(data);
    };

    const onError = () => {
        setMessage('Sorry, something went wrong.');
    };

    return (
        <Form
            {...{ formMethods }}
            onSubmit={handleSubmit(
                (data, event) => submitForm(data, event),
                onError
            )}
        >
            <S.ContactFormContent>
                <Input {...ContactFormFields.name} />
                <Input {...ContactFormFields.email} />
                <Input {...ContactFormFields.phone} />
                <Textarea {...ContactFormFields.message} />
                <Input {...ContactFormFields.agreement} />
                <S.ContactButton buttonType="submit" disabled={disable}>
                    Send message
                </S.ContactButton>
                <S.Message>{message}</S.Message>
            </S.ContactFormContent>
        </Form>
    );
};

export default ContactForm;
