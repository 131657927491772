import styled from "styled-components";

import {
    typographyPreset1,
    typographyPreset16,
    typographyPreset3,
    typographyPreset4, 
    typographyPreset11, 
    typographyPreset14, 
    typographyPreset9
} from "../../styles";

export const ValuesSectionWrapper = styled.div`
  padding: 120px 112px;
  background: ${({ theme }) => theme.palette.sectionTwoBackground};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  
  @media (max-width: 768px) {
    padding: 80px 16px;
  }
`;

export const Values = styled.div`
  padding: 4px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.white};
  ${typographyPreset1};
  width: 76px;
  
  @media (max-width: 768px) {
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
`;

export const Title = styled.h2`
  ${typographyPreset4};
  
  span {
    ${typographyPreset3};
  }
  
  @media (max-width: 768px) {
    ${typographyPreset9};
    
    span {
      ${typographyPreset11};
    }
  }
`;

export const Description = styled.p`
  ${typographyPreset16};
  color: ${({ theme }) => theme.palette.gray500};
  max-width: 575px;
  width: 100%;
  @media (max-width: 768px) {
    
    ${typographyPreset14};
    text-align: center;
  } 
`;

export const Cards = styled.div`
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  
  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
    
  @media (max-width: 450px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;