import styled from 'styled-components';

export const SliderWrapper = styled.div`
    display:block;
    margin-top:38px;

    .splide__pagination{
        display:flex;
        gap:16px;
        margin-bottom:10px;
        margin-top:48px;

        li{
            flex:1;
            height:6px;
            background:#FCEABE;
            display:flex;
            border-radius: 20.8372px;

            .is-active{
                height:100%;
                background: linear-gradient(90deg, #FCEABE 0%, #F6C035 100%);
                width:80%;
                border:20px;
            }

        }
    }
`;

export const SlideImage = styled.img`
    display:block;
    max-width:100%;
`;