import React from 'react';

import * as S from './CalendarSource.styled';

const CalendarSource = () => {
    return (
        <S.CalendarSourceWrapper>
            <S.CalendarSourceHeader>
                <S.CalendarSourceIcon>
                    <svg
                        width="41"
                        height="40"
                        viewBox="0 0 41 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g opacity="0.25" filter="url(#filter0_f_10974_408594)">
                            <rect
                                x="6.09473"
                                y="16"
                                width="29.0655"
                                height="18.1659"
                                rx="9.08297"
                                fill="#B1B4B2"
                            />
                        </g>
                        <g clip-path="url(#clip0_10974_408594)">
                            <rect
                                x="9.35059"
                                y="5.03125"
                                width="23.3779"
                                height="22.1875"
                                fill="white"
                            />
                            <path
                                d="M34.5899 0H29.3877V7.53812H36.9998V2.12556C37.0018 2.12556 36.6236 0.205566 34.5899 0Z"
                                fill="#1967D2"
                            />
                            <path
                                d="M29.3877 31.9657V31.9844V32.001L36.9998 24.3945H36.9316L29.3877 31.9657Z"
                                fill="#1967D2"
                            />
                            <path
                                d="M36.9998 7.53613H29.3877V24.3926H36.9998V7.53613Z"
                                fill="#FBBC05"
                            />
                            <path
                                d="M37 24.3945H29.3877V32L37 24.3945Z"
                                fill="#EA4335"
                            />
                            <path
                                d="M29.3877 24.4611H36.9316L36.9998 24.3936H29.3877V24.4611Z"
                                fill="#EA4335"
                            />
                            <path
                                d="M29.3701 31.9833H29.3885V31.9648L29.3701 31.9833Z"
                                fill="#34A853"
                            />
                            <path
                                d="M12.4414 24.3936V32H29.3915L29.4102 24.3936H12.4414Z"
                                fill="#34A853"
                            />
                            <path
                                d="M5.00098 24.3936V29.6822C5.06918 31.3984 6.92105 32 6.92105 32H12.4415V24.3936H5.00098Z"
                                fill="#188038"
                            />
                            <path
                                d="M12.4415 7.53812H29.3894V0H7.136C7.136 0 5.13739 0.205566 5.00098 2.32907V24.3946H12.4415V7.53812Z"
                                fill="#4285F4"
                            />
                            <path
                                d="M17.9855 20.9825C17.5977 20.9825 17.2238 20.932 16.8638 20.831C16.513 20.73 16.1899 20.5785 15.8945 20.3765C15.599 20.1653 15.3359 19.9036 15.1051 19.5915C14.8836 19.2793 14.7128 18.9166 14.5928 18.5034L16.296 17.8285C16.4161 18.2876 16.6192 18.6365 16.9053 18.8753C17.1915 19.1048 17.5516 19.2196 17.9855 19.2196C18.1793 19.2196 18.364 19.192 18.5394 19.137C18.7148 19.0727 18.8671 18.9854 18.9963 18.8753C19.1256 18.7651 19.2271 18.6365 19.301 18.4896C19.3841 18.3335 19.4256 18.1591 19.4256 17.9663C19.4256 17.5622 19.2733 17.2455 18.9686 17.0159C18.6732 16.7864 18.2624 16.6716 17.7362 16.6716H16.9192V15.0326H17.667C17.8516 15.0326 18.0316 15.0097 18.207 14.9637C18.3824 14.9178 18.5347 14.849 18.664 14.7572C18.8025 14.6562 18.9086 14.5322 18.9825 14.3853C19.0656 14.2292 19.1071 14.0501 19.1071 13.8481C19.1071 13.536 18.9963 13.2834 18.7748 13.0906C18.5532 12.8886 18.2532 12.7876 17.8747 12.7876C17.4685 12.7876 17.1546 12.8978 16.933 13.1182C16.7207 13.3294 16.573 13.5681 16.4899 13.8344L14.8282 13.1595C14.9113 12.9299 15.0359 12.6958 15.2021 12.4571C15.3682 12.2092 15.576 11.9888 15.8252 11.796C16.0837 11.594 16.3837 11.4333 16.7253 11.3139C17.0669 11.1854 17.4592 11.1211 17.9024 11.1211C18.3547 11.1211 18.7655 11.1854 19.1348 11.3139C19.5133 11.4425 19.8364 11.6215 20.1041 11.8511C20.3719 12.0714 20.5796 12.3377 20.7273 12.6499C20.875 12.9529 20.9489 13.2834 20.9489 13.6415C20.9489 13.917 20.9119 14.1649 20.8381 14.3853C20.7735 14.6057 20.6858 14.8031 20.575 14.9775C20.4642 15.152 20.3349 15.3035 20.1872 15.432C20.0488 15.5514 19.9057 15.6478 19.758 15.7213V15.8314C20.2011 16.0059 20.5657 16.286 20.8519 16.6716C21.1473 17.0572 21.295 17.5439 21.295 18.1315C21.295 18.5447 21.2166 18.9258 21.0596 19.2747C20.9027 19.6144 20.6765 19.9128 20.3811 20.1699C20.0949 20.427 19.7487 20.6244 19.3425 20.7622C18.9363 20.9091 18.484 20.9825 17.9855 20.9825Z"
                                fill="#4285F4"
                            />
                            <path
                                d="M24.6019 20.7622V13.4625L22.9264 14.1649L22.2617 12.6361L25.0451 11.3415H26.416V20.7622H24.6019Z"
                                fill="#4285F4"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_f_10974_408594"
                                x="0.634727"
                                y="10.54"
                                width="39.9854"
                                height="29.086"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    stdDeviation="2.73"
                                    result="effect1_foregroundBlur_10974_408594"
                                />
                            </filter>
                            <clipPath id="clip0_10974_408594">
                                <rect
                                    x="5"
                                    width="32.0004"
                                    height="32.0013"
                                    rx="6"
                                    fill="white"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </S.CalendarSourceIcon>
                <S.CalendarSourceTitle>Google Calendar</S.CalendarSourceTitle>
            </S.CalendarSourceHeader>
            <S.CalendarSourceMain>
                <S.CalendarSourceDateSetting>
                    Next 7 days
                </S.CalendarSourceDateSetting>
                <S.CalendarSourceDatePeriod>
                    May 10-17, 2023
                </S.CalendarSourceDatePeriod>
                <S.CalendarSourceLine />
                <S.CalendarSourceDay>
                    <S.CalendarSourceDayInfo>
                        Monday, May 15
                    </S.CalendarSourceDayInfo>
                    <S.CalendarSourceDayEvent>
                        <S.CalendarSourceDayEventName>
                            Event 1
                        </S.CalendarSourceDayEventName>
                        <S.CalendarSourceDayEventDetails>
                            4:00 AM - 6:00 AM | 2 hours
                        </S.CalendarSourceDayEventDetails>
                    </S.CalendarSourceDayEvent>
                    <S.CalendarSourceDayEvent>
                        <S.CalendarSourceDayEventName>
                            Event 2
                        </S.CalendarSourceDayEventName>
                        <S.CalendarSourceDayEventDetails>
                            10:00 AM - 11 AM | 1 hour
                        </S.CalendarSourceDayEventDetails>
                    </S.CalendarSourceDayEvent>
                </S.CalendarSourceDay>
                <S.CalendarSourceLine />
                <S.CalendarSourceDay>
                    <S.CalendarSourceDayInfo>
                        Monday, May 17
                    </S.CalendarSourceDayInfo>
                    <S.CalendarSourceDayEvent>
                        <S.CalendarSourceDayEventName>
                            Event 1
                        </S.CalendarSourceDayEventName>
                        <S.CalendarSourceDayEventDetails>
                            10:00 AM - 11 AM | 1 hour
                        </S.CalendarSourceDayEventDetails>
                    </S.CalendarSourceDayEvent>
                    <S.CalendarSourceDayEvent>
                        <S.CalendarSourceDayEventName>
                            Event 2
                        </S.CalendarSourceDayEventName>
                        <S.CalendarSourceDayEventDetails>
                            5:00 PM - 5:30 PM | 30 min
                        </S.CalendarSourceDayEventDetails>
                    </S.CalendarSourceDayEvent>
                </S.CalendarSourceDay>
                <S.CalendarSourceLine />
            </S.CalendarSourceMain>
        </S.CalendarSourceWrapper>
    );
};

export default CalendarSource;
