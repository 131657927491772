import React from 'react';
import * as S from './ProductHunt.styled';
import ProductHuntItem from './ProductHuntItem';

const ProductHuntSource = () => {
    return (
        <S.ProductHuntSourceWrapper>
            <S.ProductHuntSourceHeader>
                <S.ProductHuntSourceIcon>
                    <svg
                        width="41"
                        height="40"
                        viewBox="0 0 41 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g opacity="0.25" filter="url(#filter0_f_6009_454221)">
                            <rect
                                x="6.09473"
                                y="16"
                                width="29.0655"
                                height="18.1659"
                                rx="9.08297"
                                fill="url(#paint0_linear_6009_454221)"
                            />
                        </g>
                        <rect
                            x="5.21387"
                            y="0.213867"
                            width="31.4876"
                            height="31.4876"
                            rx="6"
                            fill="#D85637"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 9H23C25.7614 9 28 11.2386 28 14C28 16.7614 25.7614 19 23 19H19V23H16V9ZM19 16V12H23C24.1046 12 25 12.8954 25 14C25 15.1046 24.1046 16 23 16H19Z"
                            fill="white"
                        />
                        <defs>
                            <filter
                                id="filter0_f_6009_454221"
                                x="0.634727"
                                y="10.54"
                                width="39.9854"
                                height="29.086"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    stdDeviation="2.73"
                                    result="effect1_foregroundBlur_6009_454221"
                                />
                            </filter>
                            <linearGradient
                                id="paint0_linear_6009_454221"
                                x1="22.6363"
                                y1="34.7077"
                                x2="16.7117"
                                y2="13.8258"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#FF4500" />
                                <stop offset="1" stop-color="#F04100" />
                            </linearGradient>
                        </defs>
                    </svg>
                </S.ProductHuntSourceIcon>
                <S.ProductHuntSourceTitle>
                    Product Hunt
                </S.ProductHuntSourceTitle>
            </S.ProductHuntSourceHeader>
            <S.ProductHuntSourceContainer>
                <ProductHuntItem
                    title="Composable Studio"
                    description="Summarize, interact, translate your documents with AI"
                    image="https://ph-files.imgix.net/de50afe5-8309-44c2-b3dc-66d8881a3a15.png?auto=format"
                    votes="118"
                    comments="88"
                />
                <ProductHuntItem
                    title="Capacities"
                    description="A studio for your mind"
                    image="https://ph-files.imgix.net/cc32e688-4599-46d8-b585-25e756760081.png?auto=format"
                    votes="99"
                    comments="36"
                />
                <ProductHuntItem
                    title="Jitsu 2.0"
                    description="A studio for your mind"
                    image="https://ph-files.imgix.net/4550cc9b-0d9e-4e62-9a45-9228066013ae.png?auto=format"
                    votes="99"
                    comments="36"
                />
                <ProductHuntItem
                    title="Afforai"
                    description="Summarize, interact, translate your documents with AI"
                    image="https://ph-files.imgix.net/ccb3ad94-5596-4b25-a5fa-748793c3be74.png?auto=format"
                    votes="118"
                    comments="88"
                />
            </S.ProductHuntSourceContainer>
        </S.ProductHuntSourceWrapper>
    );
};

export default ProductHuntSource;
