import styled from "styled-components";

export const SingleStripeBlock = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #eee;

`

export const SingleStripeBlockName = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
`

export const SingleStripeBlockValue = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
`

export const SingleStripeBlockTime = styled.div`
    color: #828584;
    font-weight: 500;

`