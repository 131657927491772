import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby';

import Layout from '../containers/Layout/Layout';
import getSectionComponent from '../utils/sectionComponentsMap';
import SectionI from '../types/Section.type';

const Page = ({ pageContext, data }: any) => {
    const { sections } = data.page.sections;

    return (
        <Layout {...pageContext} thumbnail={data.page.featuredImage?.node}>
            <Seo post={data.page} />
            {sections
                ?.filter(({ fieldGroupName }: SectionI) => fieldGroupName)
                .map(
                    ({ fieldGroupName, ...props }: SectionI, index: number) => {
                        const Section = getSectionComponent(fieldGroupName);
                        return (
                            <Section
                                key={fieldGroupName + index}
                                title={pageContext.title}
                                settings={pageContext.settings}
                                dataKey={fieldGroupName}
                                {...props}
                            />
                        );
                    }
                )}
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query ($id: String) {
        page: wpPage(id: { eq: $id }) {
            seo {
                opengraphPublisher
                breadcrumbs {
                    text
                    url
                }
                opengraphPublishedTime
                opengraphModifiedTime
                metaRobotsNofollow
                metaKeywords
                metaDesc
                fullHead
                cornerstone
                canonical
                opengraphTitle
                opengraphSiteName
                opengraphUrl
                opengraphType
                readingTime
                twitterDescription
                title
                twitterTitle
                twitterImage {
                    altText
                    srcSet
                    sourceUrl
                }
                schema {
                    articleType
                    pageType
                    raw
                }
                opengraphAuthor
                metaRobotsNoindex
                opengraphDescription
            }

            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                        publicURL
                    }
                }
            }
            sections {
                fieldGroupName
                sections {
                    ... on WpPage_Sections_Sections_IntroSection {
                        fieldGroupName
                        icons {
                            icon {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                            positionY
                            positionYMobile
                            name
                        }
                        builderSources {
                            category
                            icons {
                                icon {
                                    altText
                                    localFile {
                                        publicURL
                                        childImageSharp {
                                            gatsbyImageData
                                        }
                                    }
                                }
                                name
                                urlName
                            }
                        }
                        sectionHeader {
                            title
                            subtitle
                        }
                        form {
                            image {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                            button {
                                title
                                url
                            }
                            imageDescription
                            inputPlaceholder
                            title
                        }
                        labels {
                            text
                        }
                        socialButtons {
                            icon {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                                altText
                            }
                            link
                        }
                    }
                    ... on WpPage_Sections_Sections_ValuesSection {
                        fieldGroupName
                        pageDescription
                        blocks {
                            description
                            icon {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                            title
                        }
                        pageTitle
                    }
                    ... on WpPage_Sections_Sections_OpportunitySection {
                        fieldGroupName
                        sectionDescription
                        cardItems {
                            description
                            title
                            id
                        }
                        images {
                            image {
                                altText
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                        sectionTitle
                    }
                    ... on WpPage_Sections_Sections_ContactSection {
                        fieldGroupName
                        sectionTitle
                    }
                    ... on WpPage_Sections_Sections_MailboxSection {
                        fieldGroupName
                        sectionTitle
                        items {
                            content {
                                description
                                title
                            }
                            image {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                        }
                        sectionDescription
                    }
                    ... on WpPage_Sections_Sections_SourcesSection {
                        fieldGroupName
                        sectionTitle
                        button {
                            title
                            url
                            target
                        }
                        icons {
                            icon {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                        }
                        textField
                        sectionDescription
                    }
                    ... on WpPage_Sections_Sections_TestimonialsSection {
                        fieldGroupName
                        sectionTitle
                        cards {
                            testimonial
                            title
                            name
                            socials {
                                link
                                icon {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData
                                        }
                                        publicURL
                                    }
                                }
                            }
                            avatar {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                    publicURL
                                }
                            }
                        }
                        sectionDescription
                    }
                    ... on WpPage_Sections_Sections_PreFooterSection {
                        fieldGroupName
                        sectionDescription
                        form {
                            placeholder
                            button {
                                target
                                title
                                url
                            }
                            socials {
                                link
                                icon {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData
                                        }
                                        publicURL
                                    }
                                }
                            }
                        }
                        sectionTitle
                    }
                    ... on WpPage_Sections_Sections_PrivacySection {
                        fieldGroupName
                        sectionname
                        sectionnamedescription
                        timestamp
                        websitedescription
                        infoBlocks {
                            fieldGroupName
                            infoBlockDescription
                            infoBlockTitle
                            subInfoBlocks {
                                fieldGroupName
                                subInfoBlockDescription
                                subInfoBlockTitle
                            }
                        }
                    }
                    ... on WpPage_Sections_Sections_TextPageSection {
                        content
                        fieldGroupName
                    }
                }
            }
        }
    }
`;
