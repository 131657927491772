import React from 'react';
import ContactSectionI from './ContactSection.type';

//styles and icons
import * as S from './ContactSection.styled';
import ContactForm from '../../components/forms/ContactForm/ContactForm';

const ContactSection = ({
    sectionTitle,
    sectionDescription,
    settings,
}: any) => {
    return (
        <S.ContactWrapper>
            <S.ContactInnerWrapper>
                <S.Title>{sectionTitle}</S.Title>
                <S.Description>{sectionDescription}</S.Description>
                <S.ContactFormWrapper>
                    <ContactForm emails={settings.options.formEmails} />
                </S.ContactFormWrapper>
            </S.ContactInnerWrapper>
        </S.ContactWrapper>
    );
};

export default ContactSection;
