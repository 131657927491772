import React, { useState } from 'react';

import MobileSliderI from './MobileSlider.type';

import '@splidejs/splide/css/core';
import '@splidejs/react-splide/css/core';
import * as Styled from './MobileSlider.styled';

import * as S from '../../sections/OpportunitySection/OpportunitySection.styled';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { SectionCard } from '../../sections/OpportunitySection/SectionCard';

const MobileSlider = ({ cardItems, cardImages }: MobileSliderI) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <Styled.SliderWrapper>
            <Splide
                onMoved={(splide) => {
                    setCurrentSlide(splide.index);
                }}
                hasTrack={false}
                aria-label="Slider"
                options={{
                    drag: true,
                    arrows: false,
                    padding: { right: '15%' },
                    gap: '1.6rem',
                }}
            >
                <Styled.SlideImage
                    className="active"
                    src={cardImages[currentSlide].image.localFile.publicURL}
                />
                <ul className="splide__pagination"></ul>
                <SplideTrack>
                    {cardItems.map((item, index) => (
                        <SplideSlide key={item.id}>
                            <S.CardWrapper
                                className="article"
                                data-reveal={`#picture_${item.id}`}
                            >
                                <SectionCard
                                    key={item.id}
                                    id={item.id}
                                    title={item.title}
                                    description={item.description}
                                />
                            </S.CardWrapper>
                        </SplideSlide>
                    ))}
                </SplideTrack>
            </Splide>
        </Styled.SliderWrapper>
    );
};

export default MobileSlider;
