import React from 'react';
import ITextPageSection from './TextPageSection.type';
import * as S from './TextPageSection.styled';

const TextPageSection = ({ content, title }: ITextPageSection) => {
    return (
        <S.SectionWrapper>
            <S.Title>{title}</S.Title>
            <div
                className="textPage"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>
        </S.SectionWrapper>
    );
};

export default TextPageSection;
