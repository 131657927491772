import styled from "styled-components";
import {
    typographyPreset9
} from "../../styles";


export const SectionWrapper = styled.div`

  padding: 50px 16px;
  margin:auto;
  max-width:1200px;
  width: 100%;
  position: relative;
  min-height:80vh;
  

  
  @media (max-width: 768px) {
    padding: 0 16px;
  }

  p{
    margin-bottom:15px;
  }
  .textPage{
    h1,h2,h3,h4{
      margin-bottom:20px;
    }
  }

  img{
    margin-bottom:15px;
  }
`;

export const Title = styled.h1`
    ${typographyPreset9}
    margin-bottom:30px;
    @media (max-width: 575px) {
        text-align:left;
    }
`;