import React from 'react';
import * as S from './SingleStripeBlock.styled';
import ISingleStripeBlock from './SingleStripeBlock.type';

const SingleStripeBlock = ({ name, value, time }: ISingleStripeBlock) => {
    return (
        <S.SingleStripeBlock>
            <S.SingleStripeBlockName>{name}</S.SingleStripeBlockName>
            <S.SingleStripeBlockValue>{value}</S.SingleStripeBlockValue>
            <S.SingleStripeBlockTime>{time}</S.SingleStripeBlockTime>
        </S.SingleStripeBlock>
    );
};

export default SingleStripeBlock;
