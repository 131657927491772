import styled from "styled-components";

export const ProductHuntSourceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.white};
    margin-bottom:35px;
`;

export const ProductHuntSourceHeader = styled.div`
    display: flex;
    justify-content:flex-start;
    align-items:center;
    margin-bottom:15px;
    margin-right:auto;
`

export const ProductHuntSourceIcon = styled.div`
    svg{
    margin-right: 16px;
    margin-bottom: -12px;
    }
`

export const ProductHuntSourceTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    text-align: left;
`

export const ProductHuntSourceContainer = styled.div`
    display: flex;
    width:100%;
    flex-wrap: wrap;
    justify-content: baseline;
    gap: 15px;
`