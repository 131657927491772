import React from 'react';

import CalendarSource from './CalendarSource';
import DateSource from './DateSource';
import HackerNewsSource from './HackerNewsSource';
import InstagramSource from './InstagramSource';
import ProductHuntSource from './ProductHuntSource';
import RSSSource from './RSSSource';
import RedditSource from './RedditSource';
import ISourcesList from './SourcesList.type';
import StripeSource from './StripeSource';
import TwitterSource from './TwitterSource';
import WeatherSource from './WeatherSource';
import YoutubeSource from './YoutubeSource';
import TiktokSource from './TiktokSource';

const SourcesList = ({ sources }: ISourcesList) => {
    const displaySource = (sourceName: string) => {
        console.log(sourceName);
        switch (sourceName) {
            case 'todaysDate':
                return <DateSource />;

            case 'googleCalendar':
                return <CalendarSource />;

            case 'hackerNews':
                return <HackerNewsSource />;

            case 'reddit':
                return <RedditSource />;

            case 'rss':
                return <RSSSource />;

            case 'x':
                return <TwitterSource />;

            case 'twitterTweets':
                return <TwitterSource />;

            case 'weather':
                return <WeatherSource />;

            case 'youtube':
                return <YoutubeSource />;

            case 'productHunt':
                return <ProductHuntSource />;

            case 'stripe':
                return <StripeSource />;

            case 'instagram':
                return <InstagramSource />;

            case 'tiktok':
                return <TiktokSource />;

            default:
                return;
        }
    };

    return <div>{sources.map((item) => displaySource(item))}</div>;
};

export default SourcesList;
