import styled, { css } from 'styled-components';

import { typographyPreset2, fontFamily1 } from '../../styles';

import { ButtonType } from './Button.type';

export const StyledButton = styled.button<{ $type: ButtonType }>`
  ${fontFamily1};
  ${typographyPreset2};
  background-color: ${({theme}) => theme.palette.gold};
  color: ${({ theme }) => theme.palette.black};
  border: 1px solid ${({ theme }) => theme.palette.gold};
  border-radius: 64px;
  padding: 10px 18px;
  text-align:center;

  :disabled{
    opacity: 0.5;
  }


  ${({ theme, $type }) =>
    $type === 'secondary' &&
    css`
      color: ${theme.palette.black};
      background-color: ${theme.palette.white};
      border: 1px solid ${theme.palette.gray};
      border-radius: 64px;
      // :hover {
      //   background: transparent;
      // }
    `} 

  
`;



export const StyledLink = styled.a<{ $type: ButtonType }>`
&&&{
  ${fontFamily1}
  ${typographyPreset2}
  height:44px;
  padding: 10px 18px;
  text-align:center;
  letter-spacing:0px;
  text-transform:none
}


min-width:100px;
display:block;
white-space:no-wrap;
background-color: ${({theme}) => theme.palette.gold};
  color: ${({ theme }) => theme.palette.black};
  border: 1px solid ${({ theme }) => theme.palette.gold};
  border-radius: 64px;
  padding: 10px 18px;
  width: auto;
    
  @media (max-width: 1280px) {
    width:100%;
    max-width:none;
  }

  ${({ theme, $type }) =>
    $type === 'secondary' &&
    css`
    
    max-width: 185px;
    width: auto;
    text-align:center;
      color: ${theme.palette.black};
      background-color: ${theme.palette.white};
      border: 1px solid ${theme.palette.gray};
      border-radius: 64px;
    `}
`;