import styled from "styled-components";

export const HackerNewsSourcePost= styled.div`
    width: calc(50% - 10px);

    @media (max-width: 1250px) {
        width:100%;
      }
`;

export const HackerNewsSourceTitle = styled.div`
    display: block;
    font-weight: 500;
    color: #272828;
    margin-bottom: 8px;
    text-align: left;
`

export const HackerNewsSourceMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 14px;
    color: #656766;
`

export const HackerNewsSourceItem = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`