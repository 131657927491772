import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import * as S from './Input.styled';
import { InputProps } from './types';

const Input: React.FC<InputProps> = ({
    id,
    name,
    placeholder,
    label = '',
    type = 'text',
    className,
    showError = true,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext<Record<string, string>>();
    const { field } = useController({ name, control });

    const errorMessage = errors[field.name]?.message;
    const isError = Boolean(errorMessage) && showError;

    return (
        <div className={className}>
            {type !== 'checkbox' ? (
                <>
                    <S.Top $error={isError}>
                        <label>{label}</label>
                        {isError && <span>{errorMessage}</span>}
                    </S.Top>

                    <S.Input
                        {...rest}
                        name={id}
                        type={type}
                        placeholder={placeholder}
                        value={field.value || ''}
                        onChange={field.onChange}
                        $error={isError}
                    />
                </>
            ) : (
                <>
                    {isError && <span>{errorMessage}</span>}
                    <S.InputCheckboxWrapper>
                        <S.InputCheckbox
                            {...rest}
                            name={id}
                            type={type}
                            placeholder={placeholder}
                            value={field.value}
                            onChange={field.onChange}
                            $error={isError}
                        />
                        <S.CheckboxField $error={isError} />
                        <div dangerouslySetInnerHTML={{ __html: label }} />
                    </S.InputCheckboxWrapper>
                </>
            )}
        </div>
    );
};

export default Input;
