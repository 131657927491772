import React from 'react';
import * as S from './TiktokSource.styled';
import TiktokSourcePost from './TiktokSourcePost';

const TiktokSource = () => {
    return (
        <S.TiktokSourceWrapper>
            <S.TiktokSourceHeader>
                <S.TiktokSourceIcon>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="41"
                        height="40"
                        fill="none"
                    >
                        <rect
                            width="31.488"
                            height="31.488"
                            x="5.214"
                            y=".214"
                            fill="url(#a)"
                            rx="6"
                        />
                        <g
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            filter="url(#b)"
                        >
                            <path
                                fill="#EE1D52"
                                d="M24.498 13.633a6.698 6.698 0 0 0 3.909 1.24v-2.777c-.275 0-.55-.029-.819-.088v2.213a6.694 6.694 0 0 1-3.913-1.255v5.713a5.199 5.199 0 0 1-2.744 4.563 5.185 5.185 0 0 1-5.314-.252 5.188 5.188 0 0 0 8.881-3.63v-5.727Zm1.013-2.833a3.886 3.886 0 0 1-1.013-2.288v-.36h-.777a3.929 3.929 0 0 0 1.79 2.648Zm-8.095 9.99a2.371 2.371 0 0 1 1.902-3.81c.244 0 .488.038.721.111v-2.87a5.475 5.475 0 0 0-.823-.046v2.231a2.37 2.37 0 0 0-2.913 1.368 2.377 2.377 0 0 0 1.127 3.017h-.014Z"
                            />
                            <path
                                fill="#fff"
                                d="M23.674 12.948a6.694 6.694 0 0 0 3.914 1.254V11.99a3.903 3.903 0 0 1-2.077-1.19 3.924 3.924 0 0 1-1.79-2.648h-2.045V19.36a2.377 2.377 0 0 1-1.618 2.242 2.371 2.371 0 0 1-2.642-.811 2.374 2.374 0 0 1-.405-3.982 2.372 2.372 0 0 1 2.19-.403v-2.231a5.182 5.182 0 0 0-4.678 3.231 5.194 5.194 0 0 0 1.094 5.584 5.187 5.187 0 0 0 7.33-1.644c.483-.805.74-1.727.741-2.667l-.014-5.731Z"
                            />
                            <path
                                fill="#69C9D0"
                                d="M27.589 11.99v-.597a3.879 3.879 0 0 1-2.077-.593 3.87 3.87 0 0 0 2.077 1.19Zm-3.867-3.838c0-.106-.033-.218-.046-.324v-.361h-2.822V18.68a2.372 2.372 0 0 1-3.437 2.111 2.372 2.372 0 0 0 4.26-1.43V8.151h2.045Zm-4.52 6.019v-.635a5.182 5.182 0 0 0-5.545 3.301 5.195 5.195 0 0 0 1.96 6.153 5.191 5.191 0 0 1 3.6-8.796l-.014-.023Z"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="a"
                                x1="5.854"
                                x2="32.947"
                                y1=".214"
                                y2="34.134"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop />
                                <stop offset="1" stop-color="#323232" />
                            </linearGradient>
                            <filter
                                id="b"
                                width="19.2"
                                height="19.7"
                                x="11.187"
                                y="6.187"
                                color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    result="hardAlpha"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                />
                                <feOffset dy=".5" />
                                <feGaussianBlur stdDeviation="1.25" />
                                <feComposite
                                    in2="hardAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                />
                                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                <feBlend
                                    in2="shape"
                                    result="effect1_innerShadow_6009_245019"
                                />
                            </filter>
                        </defs>
                    </svg>
                </S.TiktokSourceIcon>
                <S.TiktokSourceTitle>TikTok</S.TiktokSourceTitle>
            </S.TiktokSourceHeader>
            <S.TiktokSourceRow>
                <TiktokSourcePost
                    fullUsername="Digest"
                    username="@usedigest"
                    avatar="https://pbs.twimg.com/profile_images/1596136033548107776/In-Ysy1l_x96.jpg"
                    media="https://images.pexels.com/photos/1334605/pexels-photo-1334605.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    content="Just landed in this beautiful city."
                    likes="162"
                    shares="93"
                    date="Jan 23, 2024"
                />
                <TiktokSourcePost
                    fullUsername="Digest"
                    username="@usedigest"
                    avatar="https://pbs.twimg.com/profile_images/1596136033548107776/In-Ysy1l_x96.jpg"
                    media="https://images.pexels.com/photos/127905/pexels-photo-127905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    content="Traveling now. Guess where we’re going?"
                    likes="20"
                    shares="10"
                    date="Jan 19, 2024"
                />
            </S.TiktokSourceRow>
        </S.TiktokSourceWrapper>
    );
};

export default TiktokSource;
