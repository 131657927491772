import styled from "styled-components";

export const SourceIcons = styled.div`
    margin-top: 20px;
`;

export const SourceIconsCategory = styled.div`
   display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    gap: 12px;
    margin-bottom: 4px;
    margin-top: 8px;
    color: #656766;
    margin-bottom: 15px;

    &:after{
        content: "";
        flex: 1 1;
        display: block;
        height: 1px;
        background: #f5f7f6;
    }
`;

export const SourceIconsRow = styled.div`
    width: 100%;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 15px;
`;

export const SourceIconsWrapper = styled.div`
    border: 1px solid #eaeceb;
    border-radius: 8px;
    padding: 10px 12px;
    width: 100%;
    cursor: pointer;
    transition: 0.2s;

    &:hover{
        background: #f9fbfa;
    }

`;

export const SourceIconsImage = styled.img`
    max-width:100%;
`

export const SourceIconsName= styled.div`
    font-size: 14px;
    margin-bottom: 6px;
    max-width: 100%;
    max-width: 90px;
    word-wrap: break-word;
`