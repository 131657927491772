import React from 'react';
import * as S from './HackerNewsSourcePost.styled';
import IHackerNewsSourcePost from './HackerNewsSourcePost.type';

const HackerNewsSourcePost = ({
    title,
    votesCount,
    commentsCount,
    urlName,
}: IHackerNewsSourcePost) => {
    return (
        <S.HackerNewsSourcePost>
            <S.HackerNewsSourceTitle>{title}</S.HackerNewsSourceTitle>
            <S.HackerNewsSourceMeta>
                <S.HackerNewsSourceItem>
                    <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.500043 8H9.50004C9.59116 7.99969 9.68048 7.97284 9.75838 7.92233C9.83628 7.87181 9.89981 7.79955 9.94214 7.71333C9.98447 7.6271 10.004 7.53017 9.99859 7.43297C9.9932 7.33577 9.96311 7.24198 9.91154 7.1617L5.41154 0.215985C5.22504 -0.0719951 4.77604 -0.0719951 4.58904 0.215985L0.0890427 7.1617C0.0369551 7.24182 0.0064098 7.33565 0.000725322 7.43301C-0.00495916 7.53037 0.0144347 7.62754 0.0567997 7.71395C0.0991647 7.80036 0.162881 7.87271 0.241025 7.92314C0.319169 7.97357 0.408753 8.00015 0.500043 8Z"
                            fill="#656766"
                        ></path>
                    </svg>
                    {votesCount}
                </S.HackerNewsSourceItem>
                <S.HackerNewsSourceItem>
                    <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 0.5C3.1002 0.5 0.75 2.37031 0.75 4.67857C0.75 5.675 1.18887 6.58705 1.91895 7.30424C1.6626 8.31674 0.805371 9.21875 0.795117 9.22879C0.75 9.275 0.737695 9.3433 0.764355 9.40357C0.791016 9.46384 0.848437 9.5 0.914062 9.5C2.27373 9.5 3.29297 8.86116 3.79746 8.46741C4.46807 8.71451 5.2125 8.85714 6 8.85714C8.8998 8.85714 11.25 6.98683 11.25 4.67857C11.25 2.37031 8.8998 0.5 6 0.5Z"
                            fill="#828584"
                        ></path>
                    </svg>
                    {commentsCount}
                </S.HackerNewsSourceItem>
                <S.HackerNewsSourceItem>
                    <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.37915 3.62092C7.54609 4.78906 7.53007 6.66189 6.38618 7.81212C6.38403 7.81447 6.3815 7.81701 6.37915 7.81935L5.06665 9.13185C3.90903 10.2895 2.02565 10.2893 0.868213 9.13185C-0.289404 7.97443 -0.289404 6.09084 0.868213 4.93342L1.59294 4.20869C1.78513 4.01651 2.1161 4.14424 2.12603 4.41584C2.13868 4.76197 2.20075 5.10973 2.31528 5.44555C2.35407 5.55926 2.32636 5.68504 2.2414 5.77L1.98579 6.0256C1.43841 6.57299 1.42124 7.46427 1.96323 8.01701C2.51058 8.57519 3.41023 8.57851 3.96177 8.02697L5.27427 6.71466C5.82487 6.16406 5.82257 5.2741 5.27427 4.7258C5.20198 4.65365 5.12917 4.5976 5.0723 4.55844C5.03206 4.53081 4.99884 4.49416 4.97528 4.45141C4.95172 4.40866 4.93848 4.361 4.93661 4.31223C4.92888 4.10584 5.002 3.89317 5.16509 3.73008L5.5763 3.31885C5.68413 3.21102 5.85329 3.19778 5.97833 3.28504C6.12153 3.38503 6.25565 3.49743 6.37915 3.62092ZM9.13179 0.868153C7.97435 -0.289306 6.09097 -0.289462 4.93335 0.868153L3.62085 2.18065C3.61851 2.18299 3.61597 2.18553 3.61382 2.18788C2.46995 3.33811 2.45392 5.21094 3.62085 6.37908C3.74434 6.50257 3.87846 6.61496 4.02165 6.71494C4.14669 6.8022 4.31587 6.78894 4.42368 6.68113L4.83489 6.2699C4.99798 6.10681 5.0711 5.89414 5.06337 5.68775C5.0615 5.63898 5.04826 5.59132 5.0247 5.54857C5.00114 5.50582 4.96792 5.46917 4.92769 5.44154C4.87081 5.40238 4.798 5.34633 4.72571 5.27418C4.17741 4.72588 4.17511 3.83592 4.72571 3.28532L6.03821 1.97301C6.58976 1.42147 7.48939 1.42479 8.03675 1.98297C8.57874 2.53571 8.56159 3.427 8.01419 3.97438L7.75859 4.22998C7.67363 4.31494 7.64591 4.44072 7.6847 4.55443C7.79923 4.89025 7.8613 5.23801 7.87396 5.58414C7.8839 5.85574 8.21486 5.98347 8.40704 5.79129L9.13177 5.06656C10.2894 3.90916 10.2894 2.02557 9.13179 0.868153Z"
                            fill="#656766"
                        ></path>
                    </svg>
                    {urlName}
                </S.HackerNewsSourceItem>
            </S.HackerNewsSourceMeta>
        </S.HackerNewsSourcePost>
    );
};

export default HackerNewsSourcePost;
