import styled from "styled-components";
import {
    fontFamily1,
    typographyPreset11, typographyPreset12, typographyPreset13, typographyPreset14,
    typographyPreset3,
    typographyPreset4,
    typographyPreset5,
    typographyPreset9,typographyPreset18, typographyPreset2, typographyPreset20, typographyPreset8
} from "../../styles";
import EmailSVG from '../../assets/images/email.svg';

export const IntroSectionWrapper = styled.div`
  background: linear-gradient(180deg, #FFFBF3 0%, #FFD9D3 100%);
  padding: 0 112px;
  width: 100%;
  position: relative;

  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 0;
    max-width: 100vw;
  }
  
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Heading = styled.h1`
  ${fontFamily1};
  ${typographyPreset4};
  background: transparent;
  display: flex;
  gap: 16px;
  flex-direction: column;
  font-weight:500;
  padding-top: 102px;
  
    span {
      ${typographyPreset3};
      font-style: italic;
      color: ${({ theme }) => theme.palette.cherry};

    }
  
  @media (max-width: 768px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    padding-top: 48px;
    
    span {
      font-weight: 500;
      font-size: 36px;
      line-height: 42px;
    } 
  }
`;

export const CherryText = styled.span`
  color: ${({ theme }) => theme.palette.cherry};
  font-style: italic;
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
  }
`;

export const Widgets = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  justify-content: center;
  align-items: center;
`;

export const WidgetsTitle = styled.h4`
    ${typographyPreset5};
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const WidgetsIntro = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  justify-items: center;
  margin-top: 50px;
  width: 100%;
  
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 30px;
    grid-template-rows: 1fr 1fr;
    height: 260px;
  }
`;

export const LeftIcons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;


`;


export const Arrow = styled.div`
    @media (max-width: 768px) {
      img {
        width: 100%;
        max-width: 20vw;
      }
    }
`;

export const RightIcons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  
`;

export const MobileIcons = styled.div`
  margin-top:70px;
  position:relative;
  width:100%;
  display:flex;
  align-items;center;
  justify-content:space-around;
`
export const WidgetsIntroMobile = styled.div`
    position:relative;
    width:100%;
    padding:20px;
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const Icon = styled.img`
  position:absolute;
  top:${props => props.offset || 0}px;
  max-width:65px;
  max-height:65px;
  
  @media (max-width:992px) {
    top:${props => props.offsetMobile || 30}px;
  }
`

export const IconWrapper = styled.div`
    position:relative;
    cursor:pointer;
    min-height:80px;

    @media (max-width:992px) {
      width:20%;
  }
  
`

export const LeftIconsInRightIcons = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
}

    @media (max-width: 400px) {
      .animateWebRss {
        transform: translateY(747px) translateX(calc(100vw - 65.5vw));
        transition: all 3s;
      }

    }
`;



export const CreateDigestInfo = styled.div`
  margin-top: 120px;
  position: relative;
  z-index: 10;
  
  @media (max-width: 768px) {
    margin-top: 65px;
  }
`;

export const CreateDigestInfoTitle = styled.h3`
  ${typographyPreset11};
  font-style: italic;
  padding: 0 30px;

  
  span {
    font-style: normal;
    ${typographyPreset9};
  }
  
  @media (max-width: 768px) {
    ${typographyPreset18};
    text-align: center;
    
    span {
      ${typographyPreset20};
    }
  }
`;

export const CreateDigestInfoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

export const PeopleInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  
  @media (max-width: 768px) {
    padding: 0 26px;
    img {
      max-width: 92px;
      width: 100%;
      height: 40px;
    }
  }
`;

export const PeopleInfoTitle = styled.div`
  ${typographyPreset13};
  span {
    ${typographyPreset12};
  }
  
  @media (max-width: 768px) {
    ${typographyPreset8};
    
    span {
      ${typographyPreset2};
    }
  }
`;

export const SendYourEmail = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  margin-top: 44px;
  gap: 12px;
  
  input {
    width: 374px;
    height: 21px;
    padding: 20px 31px 23px 50px;
    border-radius: 1000px;
    background-image: url(${EmailSVG});
    background-position: 20px;
    background-repeat: no-repeat;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    
    ::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
    }
  }
  
  button {
    max-width: 185px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    
    input {
      width: 100%;
      padding: 13px 23px 13px 53px;
      height: auto;
      
      ::placeholder {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }
    }
    
    button {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  
  button {
    max-width: 185px;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Checks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin-top: 20px;
  padding-bottom: 120px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 9px;
    padding-bottom: 80px;
  }
`;

export const CheckItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const CheckTitle = styled.p`
  ${typographyPreset14};
`;

export const CreateDigestContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const Container = styled.div`
  max-width: 1216px;
  margin:auto;
  width:100%;
`;

