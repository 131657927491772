import styled from "styled-components";

export const WeatherSourceBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #eaeceb;
    flex: 1 1;
    padding: 8px;
`;

export const WeatherSourceData = styled.div`
    font-weigth:500;
    text-align:center;
    font-size:14px;
`

export const WeatherSourceDataSpan = styled.span`
    font-weight: 400;
    color: #828584;
`

export const WeatherSourceForecastIcon = styled.div`
max-width: 50px;
margin: 15px auto 15px;

`
export const WeatherSourceTemperatureContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 7px;
font-size: 13px;
font-weight: 500;
margin-bottom: 16px;

`
export const WeatherSourceTemperatureWrapper = styled.div`
display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`
export const WeatherSourceDayIcon= styled.div`

`

export const WeatherSourceHumidity= styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #717bbc;
`