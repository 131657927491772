import React from 'react';
import * as S from './TwitterSource.styled';
import TwitterSourcePost from './TwitterSourcePost';

const TwitterSource = () => {
    return (
        <S.TwitterSourceWrapper>
            <S.TwitterSourceHeader>
                <S.TwitterSourceIcon>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="41"
                        height="40"
                        fill="none"
                    >
                        <g filter="url(#a)" opacity=".25">
                            <rect
                                width="29.066"
                                height="18.166"
                                x="6.095"
                                y="16"
                                fill="url(#b)"
                                rx="9.083"
                            />
                        </g>
                        <rect
                            width="31.488"
                            height="31.488"
                            x="5.214"
                            y=".214"
                            fill="#0D0D0D"
                            rx="6"
                        />
                        <g clip-path="url(#c)">
                            <path
                                fill="#fff"
                                d="M22.522 14.924 28.48 8h-1.412l-5.172 6.012L17.765 8H13l6.247 9.09L13 24.353h1.412l5.461-6.35 4.363 6.35H29l-6.478-9.428ZM20.59 17.17l-.633-.905-5.036-7.203h2.168l4.064 5.813.633.905 5.283 7.556H24.9l-4.311-6.166Z"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="b"
                                x1="22.636"
                                x2="16.712"
                                y1="34.708"
                                y2="13.826"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset=".073" stop-color="#100F0D" />
                                <stop offset=".974" stop-color="#35322D" />
                            </linearGradient>
                            <clipPath id="c">
                                <path fill="#fff" d="M13 8h16v16.36H13z" />
                            </clipPath>
                            <filter
                                id="a"
                                width="39.985"
                                height="29.086"
                                x=".635"
                                y="10.54"
                                color-interpolation-filters="sRGB"
                                filterUnits="userSpaceOnUse"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    result="effect1_foregroundBlur_11212_47729"
                                    stdDeviation="2.73"
                                />
                            </filter>
                        </defs>
                    </svg>
                </S.TwitterSourceIcon>
                <S.TwitterSourceTitle>Tweets</S.TwitterSourceTitle>
            </S.TwitterSourceHeader>
            <S.TwitterSourceRow>
                <TwitterSourcePost
                    fullUsername="Digest"
                    username="@usedigest"
                    avatar="https://pbs.twimg.com/profile_images/1596136033548107776/In-Ysy1l_x96.jpg"
                    content="We have a new source being added to <a href='https://twitter.com/usedigest' target='_blank'>@usedigest</a> soon. Can anyone guess what it is? First one to get it right gets early access 👀 <a href='https://twitter.com/hashtag/buildinpublic?src=hashtag_click' target='_blank'>#buildinpublic</a>"
                    likes="162"
                    shares="93"
                    date="Jan 23, 2024"
                />
                <TwitterSourcePost
                    fullUsername="Digest"
                    username="@usedigest"
                    avatar="https://pbs.twimg.com/profile_images/1596136033548107776/In-Ysy1l_x96.jpg"
                    content="Stripe and YouTube sources have now been added to Digest. You can now get updates on your business or favorite YouTube channels in your Digest."
                    likes="20"
                    shares="10"
                    date="Jan 19, 2024"
                />
                <TwitterSourcePost
                    fullUsername="Digest"
                    username="@usedigest"
                    avatar="https://pbs.twimg.com/profile_images/1596136033548107776/In-Ysy1l_x96.jpg"
                    content="What sources would you like to see added to Digest? What are some of the most popular content sources that you read on a daily basis?"
                    likes="1"
                    shares="4"
                    date="Jan 9, 2024"
                />
                <TwitterSourcePost
                    fullUsername="Digest"
                    username="@usedigest"
                    avatar="https://pbs.twimg.com/profile_images/1596136033548107776/In-Ysy1l_x96.jpg"
                    content="Happy New Year! We're excited to be heads down and focused on building Digest this year. Please follow along for the ride!"
                    likes="50"
                    shares="1"
                    date="Jan 2, 2024"
                />
            </S.TwitterSourceRow>
        </S.TwitterSourceWrapper>
    );
};

export default TwitterSource;
