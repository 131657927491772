import styled from "styled-components";
import {
  typographyPreset1, typographyPreset10,
  typographyPreset2,
  typographyPreset6,
  typographyPreset7,
  typographyPreset8, typographyPreset9
} from "../../styles";
import {typographyPreset13} from "../../styles/typography";

export const CreateDigestWrapper = styled.div`
  max-width: 1216px;
  width: 100%;
  margin-top: 100px;
  background: ${({ theme }) => theme.palette.white};
`;

export const CreateDigestHeader = styled.div`
  padding: 18px 30px;
  width: 100%;
  display: flex;
  border: 0.84px solid ${({ theme }) => theme.palette.normalGray};
  justify-content: space-between;
  
  button {
    box-shadow: 0px 1px 2px 0px #1018280D;
  }
  
  @media (max-width: 1070px) {
    padding: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.gray100};
    
    button {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
    }
  }
`;

export const Logo = styled.div`
  max-width: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 55px;
  
  @media (max-width: 1070px) {
    max-width: 78px;
  }
`;

export const Title = styled.p`
  ${typographyPreset1};
  white-space: nowrap;

  @media (max-width: 1070px) {
    display: none;
  }
`;

export const StepsCreateDigest = styled.div`
  display: flex;
  align-items: center;  
  gap: 53px;

  @media (max-width: 1070px) {
    .steps {
      display: none;
    }
  }
`;

export const Steps = styled.div`

`;

export const StepsTitle = styled.p`
  ${typographyPreset7};
  white-space: nowrap;
  span {
    ${typographyPreset6};
  }
`;

export const CreationSteps = styled.div`
  display: flex;
  gap: 7px;
  margin-top: 10px;
`;

export const StepItem = styled.div`
  height: 5.07px;
  max-width: 84px;
  width: 100%;
  opacity: 0.3;
  background: ${({ theme }) => theme.palette.gold};
  border-radius: 3px;
  
  .active {
    opacity: 1;
  }
`;

export const StepItemActive = styled.div`
  height: 5.07px;
  max-width: 84px;
  width: 100%;
  background: ${({ theme }) => theme.palette.gold};
  border-radius: 3px;
`;

export const CreateDigestSettings = styled.div`
  display: grid;
  padding: 27px;
  grid-template-columns: 2.5fr 4fr;
  min-height: 638px;
  grid-gap: 67px;

  .steps-create-digest {
    display: none;
  }

  .sources-in-tablet {
    display: none;
  }
  
  @media ( max-width: 1070px ) {
    padding: 16px;
    grid-gap: 32px;
    height: auto;
    justify-items: center;
    grid-template-columns: 1fr;

    .steps-create-digest {
      display: block;
    }



    .sources-in-tablet {
      display: block;
    }
  }
`;

export const Sources = styled.div`
  border: 0.844444px solid #EAECEB;
  box-shadow: 20.2667px 0 47.2889px rgba(52, 64, 84, 0.03);
  border-radius: 6.75556px;
  padding: 30px 16px;
  
  @media (max-width: 1070px) {
    height: auto;
    width: 100%;
  }
`;

export const SourcesTitle = styled.div`
  ${typographyPreset2};
  
  p {
    ${typographyPreset8};
  }
`;

export const AddSources = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 100%;
  max-width:670px;
`;

export const NotificationWrapper = styled.div`
  display: none;
  @media (max-width: 1070px) {
    display: block;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;

  .edit-title-two {
      display: none;
  }
  
  @media (max-width: 1070px) {
    gap: 14px;

    .edit-title-one {
      display: none;
    }

    .edit-title-two {
      display: block;
    }
  }
 `;

export const EditTitle = styled.p`
  ${typographyPreset9};

  @media (max-width: 1070px) {
    ${typographyPreset13};
  }
`;

export const AddNewSource = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 17px;
  height: 125px;
  background-color: ${({ theme }) => theme.palette.gray100};
  border: 1px solid ${({ theme }) => theme.palette.gray300};
  cursor: pointer;
`;

export const AddSourceTitle = styled.p`
  ${typographyPreset10};
`;

export const SourceIconsContainer = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:3px;
  margin-top:20px;

  @media (max-width: 922px) {
    margin-top:40px;
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;

  }
`;

export const SourceIconsBlock = styled.div`
  height:100px;

  @media (max-width: 575px) {
      height:60px;
  }
`

export const ButtonWrapper = styled.div`
margin: auto;
display: flex;
  button{
    width: 150px;
    margin-bottom: 40px;
  }
`