import styled from "styled-components";
import {typographyPreset17} from "../../styles/typography";

export const NotificationWrapper = styled.div`
  background: ${({ theme }) => theme.palette.black};
  border-radius: 8px;
  position: relative;
  color: ${({ theme }) => theme.palette.white};
  padding: 12px;
  ${typographyPreset17};
  text-align: center;
`;

export const NotificationRectangle = styled.div`
  position: absolute;
  bottom: -13px;
  left: 47%;
`;