import React from 'react';

//other components
import Image from '../../components/Image/Image';

//styles and icons
import * as S from './SourcesSection.styled';
import ArrowSVG from '../../assets/images/sectionFiveArrow.svg';
import ArrowMobileSVG from '../../assets/images/sectionFourMobileArrow.svg';

//type  and constants
import SourcesSectionI from './SourcesSection.type';
import Button from '../../components/Button/Button';

const SourcesSection = ({
    sectionDescription,
    sectionTitle,
    button,
    textField,
    icons,
}: SourcesSectionI) => {
    return (
        <S.SourcesSectionWrapper>
            <img
                className="arrow-mobile"
                src={ArrowMobileSVG}
                alt="background-arrow"
            />
            <img className="arrow" src={ArrowSVG} alt="background-arrow" />
            <S.Container>
                <S.Values>Sources</S.Values>
                <S.Title dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                <S.Description>{sectionDescription}</S.Description>
                <S.IconWrapper>
                    {icons &&
                        icons.map((item) => (
                            <S.IconItem>
                                <Image
                                    altText={'digest-sources-icons'}
                                    localFile={{
                                        publicURL:
                                            item.icon.localFile.publicURL,
                                    }}
                                />
                            </S.IconItem>
                        ))}
                </S.IconWrapper>
                <S.SourcesTitle>{textField}</S.SourcesTitle>
                <Button
                    link={button.url}
                    target={button.target}
                    className={'button-add-source'}
                >
                    {button.title}
                </Button>
            </S.Container>
        </S.SourcesSectionWrapper>
    );
};

export default SourcesSection;
