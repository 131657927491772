import styled from 'styled-components';
import { typographyPreset10, typographyPreset8 } from '../../styles';

export const Textarea = styled.textarea<{ $error: boolean }>`
  ${typographyPreset8};
  max-width: 480px;
  padding: 10px 0px 10px 14px;
  width: 100%;
  color: ${({ theme }) => theme.palette.gray700};
  border: 1px solid ${({ theme, $error }) => theme.palette[$error ? 'red' : 'gray300']};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 32px;
  padding: 17px;
  resize: none;
  height: 132px;

  ::placeholder {
    color: ${({ theme }) => theme.palette.gray500};
  }
`;

export const Top = styled.label<{ $error: boolean }>`
  ${typographyPreset10};
  display: flex;
  justify-content: space-between;
  color: ${({ theme, $error }) => theme.palette[$error ? 'red' : 'gray700']};
  margin-bottom: 4px;
`;
