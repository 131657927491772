import styled from "styled-components";

export const YoutubeVideo = styled.div`
    width: calc(50% - 10px);

    @media (max-width: 1250px) {
        width:100%;
    }
`;

export const YoutubeVideoTitle = styled.div`
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #272828;
    margin-bottom: 6px;
`

export const YoutubeVideoDate = styled.div`
        font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
`
export const YoutubeVideoImage = styled.img`
    display: block;
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
`