import styled from "styled-components";
import {typographyPreset1, typographyPreset16, typographyPreset3, typographyPreset4} from "../../styles";
import {typographyPreset10, typographyPreset11, typographyPreset14, typographyPreset9} from "../../styles/typography";
import { CardBarProgress } from "./SectionCard/SectionCard.styled";

export const OpportunitySectionWrapper = styled.div`
  padding: 120px 112px;
  background: ${({ theme }) => theme.palette.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;

  @media (max-width: 1200px) {
    padding:30px;
  }
  
  & > img {
    position: absolute;
    right: 0;

    @media (max-width: 768px) {
        top:30px;
        z-index:0;
    }
  }
  
  @media (max-width: 768px) {
    padding: 82px 16px;
    gap: 16px;
    
    & > img { 
      width: 111px;
      height: 120px;
    }
  }
`;

export const Values = styled.div`
  padding: 4px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.primary100};
  ${typographyPreset1};
  color: ${({ theme }) => theme.palette.primary900};
  width: 136px;
  
  @media (max-width: 992px) {
    ${typographyPreset10};
    margin-bottom:18px;
  }
`;

export const Title = styled.h2`
  ${typographyPreset4};
  text-align: left;

  
  
  span {
    ${typographyPreset3};
  }
  
  @media (max-width: 992px) {
    text-align: left !important;
    ${typographyPreset9};
    width: 75%;
    margin-bottom:16px;
    
    span {
      ${typographyPreset11};
    }
  }
`;

export const Description = styled.p`
  ${typographyPreset16};
  max-width: 768px;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.palette.gray500};
  
  @media (max-width: 768px) {
    ${typographyPreset14};
    max-width: 100%;
  }
`;

export const SectionWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2.5fr 1.5fr;
  grid-gap: 96px;
  margin-top: 82px;
  
  @media (max-width: 1200px) {
    max-width: unset
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-top: 30px;
  }

  img{
    width:100%;
  }

  .articles {
  display: flex;
  flex-direction: column;
}

.article {
  padding: 10px;
}

.pictures {
  position: sticky;
  display: flex;
  top: 100px;
  right: 0;
  height: 555px;
  /* background: #444; */
}

.picture {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: auto;
  transition: 0.3s opacity, 0.5s transform;
  opacity: 0;
  transform: scale(0.8);
}

.picture.is-active {
  opacity: 1;
  transform: scale(1);
}
`;

export const SectionCardItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 26px;
  
  @media (max-width: 768px) {
    flex-direction: row;
    overflow: hidden;
  }
`;

export const SectionInfoImage = styled.div`
  display:flex;
  align-items:center;
  max-width: 630px;
  width: 100%;
  
  @media (max-width: 768px) {
   img {
     max-width: 343px;
   } 
  }
`;

export const CardWrapper = styled.div`
    height:100%;
    position: relative;
    cursor:pointer;
    display:flex;
    align-items:center;

    &:nth-child(2){
      ${CardBarProgress}{
        top:20%;
      }
    }

    &:nth-child(3){
      ${CardBarProgress}{
        top:unset;
        bottom:0;
      }
    }

`;


export const ScrollProgressContainer = styled.div`
    height: 136px;
    background: ${({ theme }) => theme.palette.primary100} ;
    width: 8px;
    position: absolute;
    left: 30px;
    top: 20%;
`;

export const ScrollProgressBar = styled.div<{ scrollBar: string | number, activeCard: boolean }>`
    height: "5px";
    background: ${({ activeCard }) => activeCard ? 'linear-gradient(180deg, #FCEABE 0%, #F6C035 100%)' : 'transparent'};
    height: ${({ scrollBar }) => scrollBar};
`;

export const Container = styled.div`
    max-width:1216px;
    width:100%;
    margin:auto;
    z-index:1;
`
