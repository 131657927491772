import React, { useEffect, useState, useLayoutEffect } from 'react';

import { useMediaQuery } from 'react-responsive';

//other components
import { Button, Image } from '../../components';

//styles and icons
import * as S from './IntroSection.styled';
import ArrowSVG from '../../assets/images/arrow.svg';
import ArrowBottomSVG from '../../assets/images/sectionOneArrow.svg';
import CheckSVG from '../../assets/images/check.svg';

//type  and constants
import IntroSectionI from './IntroSection.type';
import { CreateDigest } from '../../containers/CreateDigest';

const APP_URL = process.env.GATSBY_REACT_APP_URL;

const IntroSection = ({
    icons,
    sectionHeader,
    form,
    builderSources,
    labels,
    socialButtons,
}: IntroSectionI) => {
    const [isMobileTwo, setIsMobileTwo] = useState(false);
    const breakpoint = 992;

    const responsive = useMediaQuery({
        query: `(max-width: ${breakpoint}px)`,
    });

    const [username, setUsername] = useState('');

    const handleChangeUsername = (e: any) => {
        setUsername(e.target.value);
    };

    const [sources, setSources] = useState<string[]>([]);

    const addSource = (item: string) => {
        setSources((prev) => [item, ...prev.slice(0, 4)]);
    };

    useEffect(() => {
        setIsMobileTwo(responsive);
    }, [responsive]);

    return (
        <S.IntroSectionWrapper>
            <img src={ArrowBottomSVG} alt={'arrow'} />
            <S.Container>
                <S.Heading
                    dangerouslySetInnerHTML={{ __html: sectionHeader.title }}
                ></S.Heading>
                <S.Widgets>
                    <S.WidgetsTitle>{sectionHeader.subtitle}</S.WidgetsTitle>
                    {isMobileTwo ? (
                        <S.WidgetsIntroMobile>
                            <S.Arrow>
                                <Image
                                    altText={'digest-create-arrow'}
                                    localFile={{ publicURL: ArrowSVG }}
                                />
                            </S.Arrow>
                            <S.MobileIcons>
                                {icons.map((item, index) => (
                                    <S.IconWrapper
                                        key={index}
                                        onClick={() => addSource(item.name)}
                                    >
                                        <S.Icon
                                            src={item.icon.localFile.publicURL}
                                            alt={item.icon.altText}
                                            offset={item.positionY}
                                            offsetMobile={item.positionYMobile}
                                        />
                                    </S.IconWrapper>
                                ))}
                            </S.MobileIcons>
                        </S.WidgetsIntroMobile>
                    ) : (
                        <S.WidgetsIntro>
                            <S.LeftIcons>
                                {icons.map((item, index) =>
                                    index % 2 === 0 ? (
                                        <S.IconWrapper
                                            key={index}
                                            onClick={() => addSource(item.name)}
                                        >
                                            <S.Icon
                                                src={
                                                    item.icon.localFile
                                                        .publicURL
                                                }
                                                alt={item.icon.altText}
                                                offset={item.positionY}
                                                offsetMobile={
                                                    item.positionYMobile
                                                }
                                            />
                                        </S.IconWrapper>
                                    ) : (
                                        ''
                                    )
                                )}
                            </S.LeftIcons>
                            <S.Arrow>
                                <Image
                                    altText={'digest-create-arrow'}
                                    localFile={{ publicURL: ArrowSVG }}
                                />
                            </S.Arrow>
                            <S.RightIcons>
                                {icons.map((item, index) =>
                                    index % 2 !== 0 ? (
                                        <S.IconWrapper
                                            key={index}
                                            onClick={() => addSource(item.name)}
                                        >
                                            <S.Icon
                                                src={
                                                    item.icon.localFile
                                                        .publicURL
                                                }
                                                alt={item.icon.altText}
                                                offset={item.positionY}
                                                offsetMobile={
                                                    item.positionYMobile
                                                }
                                            />
                                        </S.IconWrapper>
                                    ) : (
                                        ''
                                    )
                                )}
                            </S.RightIcons>
                        </S.WidgetsIntro>
                    )}
                </S.Widgets>
                <S.CreateDigestContainer>
                    <CreateDigest
                        icons={icons}
                        addSource={addSource}
                        builderSources={builderSources}
                        sources={sources}
                    />
                </S.CreateDigestContainer>
                <S.CreateDigestInfo>
                    <S.CreateDigestInfoTitle
                        dangerouslySetInnerHTML={{
                            __html: form.title,
                        }}
                    ></S.CreateDigestInfoTitle>
                    <S.CreateDigestInfoContent>
                        <S.PeopleInfo>
                            <Image
                                altText={'digest-peoples'}
                                localFile={{
                                    childImageSharp:
                                        form.image.localFile.childImageSharp,
                                    publicURL: form.image.localFile.publicURL,
                                }}
                            />
                            <S.PeopleInfoTitle
                                dangerouslySetInnerHTML={{
                                    __html: form.imageDescription,
                                }}
                            ></S.PeopleInfoTitle>
                        </S.PeopleInfo>
                    </S.CreateDigestInfoContent>
                    <S.SendYourEmail>
                        <input
                            onChange={handleChangeUsername}
                            type={'text'}
                            value={username}
                            placeholder={form.inputPlaceholder}
                        />
                        <Button
                            link={`${APP_URL}/signup?name=${username}`}
                            target="_blank"
                        >
                            {form.button.title}
                        </Button>
                    </S.SendYourEmail>
                    <S.SocialButtons>
                        {socialButtons &&
                            socialButtons.map((item, index) => (
                                <Button
                                    key={index}
                                    link={item.link}
                                    target="_blank"
                                    type={'secondary'}
                                >
                                    <Image
                                        altText={'digest-social-button'}
                                        localFile={{
                                            publicURL:
                                                item.icon.localFile.publicURL,
                                        }}
                                    />
                                </Button>
                            ))}
                    </S.SocialButtons>
                    <S.Checks>
                        {labels &&
                            labels.map((item, index) => (
                                <S.CheckItem key={index}>
                                    <Image
                                        altText={'digest-check'}
                                        localFile={{ publicURL: CheckSVG }}
                                    />
                                    <S.CheckTitle>{item.text}</S.CheckTitle>
                                </S.CheckItem>
                            ))}
                    </S.Checks>
                </S.CreateDigestInfo>
            </S.Container>
        </S.IntroSectionWrapper>
    );
};

export default IntroSection;
