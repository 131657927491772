import React from 'react';
import * as S from './HackerNewsSource.styled';
import HackerNewsSourcePost from './HackerNewsSourcePost';

const HackerNewsSource = () => {
    return (
        <S.HackerNewsSourceWrapper>
            <S.HackerNewsSourceHeader>
                <S.HackerNewsSourceIcon>
                    <svg
                        width="41"
                        height="40"
                        viewBox="0 0 41 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g opacity="0.25" filter="url(#filter0_f_9230_107474)">
                            <rect
                                x="6.09473"
                                y="16"
                                width="29.0655"
                                height="18.1659"
                                rx="9.08297"
                                fill="url(#paint0_linear_9230_107474)"
                            />
                        </g>
                        <rect
                            x="5.21387"
                            y="0.213867"
                            width="31.4876"
                            height="31.4876"
                            rx="6"
                            fill="#F36003"
                        />
                        <path
                            d="M21.9508 18.6929V25H19.9885V18.5809L15 9H17.3309C20.6115 15.1438 20.405 15.3258 21.0373 16.8495C21.8061 15.1613 21.4002 15.3246 24.8254 9H27L21.9496 18.694H21.9508V18.6929Z"
                            fill="white"
                        />
                        <path
                            d="M21.9508 18.694V25H19.9885V18.5809L15 9H17.3309C20.6115 15.1438 20.405 15.3258 21.0373 16.8495C21.8061 15.1613 21.4002 15.3246 24.8254 9H27L21.9496 18.694H21.9508Z"
                            fill="white"
                        />
                        <defs>
                            <filter
                                id="filter0_f_9230_107474"
                                x="0.634727"
                                y="10.54"
                                width="39.9854"
                                height="29.0859"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    stdDeviation="2.73"
                                    result="effect1_foregroundBlur_9230_107474"
                                />
                            </filter>
                            <linearGradient
                                id="paint0_linear_9230_107474"
                                x1="22.6363"
                                y1="34.7077"
                                x2="16.7117"
                                y2="13.8258"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#FF4500" />
                                <stop offset="1" stop-color="#F04100" />
                            </linearGradient>
                        </defs>
                    </svg>
                </S.HackerNewsSourceIcon>
                <S.HackerNewsSourceTitle>Hacker News</S.HackerNewsSourceTitle>
            </S.HackerNewsSourceHeader>

            <S.HackerNewsSourceContainer>
                <HackerNewsSourcePost
                    title="What happened with ASUS routers this morning?"
                    votesCount="661"
                    commentsCount="411"
                    urlName="downtown.com"
                />
                <HackerNewsSourcePost
                    title="Numbers every LLM developer should know"
                    votesCount="397"
                    commentsCount="100"
                    urlName="github.com"
                />
                <HackerNewsSourcePost
                    title="OpenAI Brings ChatGPT to iOS"
                    votesCount="364"
                    commentsCount="219"
                    urlName="openai.com"
                />
                <HackerNewsSourcePost
                    title="Legend of Zelda game sells 10M copies in three days"
                    votesCount="273"
                    commentsCount="430"
                    urlName="finance.yahoo.com"
                />
                <HackerNewsSourcePost
                    title="User In Yer Face, a worst-practise UI experiment"
                    votesCount="264"
                    commentsCount="91"
                    urlName="userinyerface.com"
                />
                <HackerNewsSourcePost
                    title="Advertise on DuckDuckGo Search"
                    votesCount="260"
                    commentsCount="238"
                    urlName="help.duckduckgo.com"
                />
            </S.HackerNewsSourceContainer>
        </S.HackerNewsSourceWrapper>
    );
};

export default HackerNewsSource;
