import styled from "styled-components";
import {typographyPreset1, typographyPreset16, typographyPreset3, typographyPreset4} from "../../styles";

export const TestimonialsWrapper = styled.div`
  padding: 120px 112px;
  background: ${({ theme }) => theme.palette.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  position: relative;
  
  & > img {
    position: absolute;
    left: 0;
    top: 45px;
  }

  @media (max-width: 1100px) {
    padding: 80px 16px;
    justify-content: center;
    align-items: center;
    gap: 0;

    & > img {
      max-width: 91px;
      height: 80px;
      top: 20px;
    }
  }
`;

export const Values = styled.div`
  padding: 4px 12px;
  border-radius: 16px;
  background: #FCF8F2;
  ${typographyPreset1};

  @media (max-width: 1100px) {
    max-width: 107px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
`;

export const Title = styled.h2`
  ${typographyPreset4};
  
  span {
    ${typographyPreset3};
  }

  @media (max-width: 1100px) {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    margin-top: 12px;
    width: 65%;
    margin-bottom: 16px;

    span {
      font-size: 30px;
      font-style: italic;
      font-weight: 500;
      line-height: 35px;
      text-align: center;
    }
  }
`;

export const Description = styled.p`
  ${typographyPreset16};
  color: ${({ theme }) => theme.palette.gray500};
  max-width: 532px;
  width: 100%;
  
  @media (max-width: 1100px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
`;

export const CardsWrapper = styled.div`
  margin-top: 64px;
  
  .swiper {
    display: none;
  }


  @media (max-width: 1100px) {
    //grid-template-columns: 1fr 1fr;
    display: flex;

    .desktop-cards {
        display: none;
    }
  }
  
  @media (max-width: 1100px) {
    display: flex;
    overflow: hidden;
    width: 100% !important;

    .swiper {
        display: block;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-wrapper {
    max-width: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  //.swiper-slide img {
  //  display: block;
  //  width: 100%;
  //  height: 100%;
  //  object-fit: cover;
  //}

  .swiper-slide {
    width: 50%;
    
    @media (max-width: 1100px) {
      width: 70%;
    }
  }

  .swiper-slide:nth-child(2n) {
    width: 40%;

    @media (max-width: 1100px) {
      width: 80%;
    }
  }

  .swiper-slide:nth-child(3n) {
    width: 40%;

    @media (max-width: 1100px) {
      width: 90%;
    }
  }
  
  @media (max-width: 1100px) {
    width: 305px;
  }
`;  

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap:wrap;
  
  @media ( max-width: 1100px ) {
    flex-direction: row;
    overflow: hidden;
  }
`;