import styled from "styled-components";
import {typographyPreset14, typographyPreset15} from "../../styles";
import {typographyPreset1, typographyPreset21} from "../../styles/typography";

export const CardWrapper = styled.div`
  max-width: 286px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
  
  @media (max-width: 1280px) {
    max-width: 100%;
  }
  
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 28px 24px 24px;
  }
`;

export const Icon = styled.div`
  padding: 14px 15px;
  max-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.white};
  
  @media (max-width: 768px) {
    padding: 10px 11px;
  }
`;

export const Title = styled.h4`
  ${typographyPreset15};
  margin-top: 16px;
  
  @media (max-width: 768px) {
    ${typographyPreset1};
  }
`;

export const Description = styled.p`
  margin-top: 19px;
  color: ${({ theme }) => theme.palette.gray500};
  ${typographyPreset14};
  
  @media (max-width: 768px) {
    margin-top: 12px;
    ${typographyPreset21};
  }
`;