import React from 'react';
import ReactHelmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import HelmetI from './Helmet.type';

const Helmet = ({ thumbnail }: HelmetI) => {
    const dataQuery = useStaticQuery(data);
    const defaultThumbnail = dataQuery.wp.options.options.defaultThumbnail;

    return (
        <ReactHelmet
            title={`Digest`}
            meta={[
                {
                    name: 'description',
                    content:
                        'Digest of your favorite source, delivered daily. Always free',
                },
                {
                    name: 'robots',
                    content: 'index,follow',
                },
                {
                    name: 'copyright',
                    content: 'Copyrights © Digest. All rights reserved.',
                },

                {
                    property: 'og:image',
                    content: thumbnail
                        ? thumbnail.localFile.publicURL
                        : defaultThumbnail.localFile.publicURL,
                },
            ]}
            link={
                [
                    // {
                    //     rel: 'icon',
                    //     type: 'image/x-icon',
                    //     sizes: '16x16',
                    //     href: FaviconIco,
                    // },
                    // {
                    //     rel: 'mask-icon',
                    //     href: SafariPinnedTab,
                    //     color: '#ffffff',
                    // },
                ]
            }
            htmlAttributes={{ lang: 'en-US' }}
            script={[
                {
                    type: 'text/javascript',
                    innerHTML: `var script = document.createElement('script'); script.id = 'fathom';
                script.dataset.site = 'LENTAOBY';
                script.src = "https://cdn.usefathom.com/script.js";
                document.getElementsByTagName('head')[0].appendChild(script);`,
                },
            ]}
        ></ReactHelmet>
    );
};

export default Helmet;

const data = graphql`
    query {
        wp {
            options {
                options {
                    defaultThumbnail {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`;
