import React from 'react';

//other components

//styles and icons
import * as S from './Card.styled';

//type  and constants
import CardI from "./Card.type";
import {Image} from "../Image";


const Card = ({ icon, title, description }: CardI) => {
    return (
        <S.CardWrapper>
            <S.Icon>
                <Image altText={'digest-card-icon'} localFile={{ publicURL: icon }} />
            </S.Icon>
            <S.Title>
                { title }
            </S.Title>
            <S.Description>
                { description }
            </S.Description>
        </S.CardWrapper>
    );
};

export default Card;
