import styled from "styled-components";
import {
  typographyPreset1,
  typographyPreset22,
  typographyPreset9,
  typographyPreset16,
  typographyPreset10,
  typographyPreset3,
  typographyPreset4,
  typographyPreset18,
  typographyPreset14
} from "../../styles";

export const MailboxSectionWrapper = styled.div`
  padding: 120px 112px;
  background: ${({ theme }) => theme.palette.milk};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 64px;
  
  @media (max-width: 768px) {
    padding: 80px 16px;
    gap: 18px;
  }
`;

export const Mailbox = styled.div`
  padding: 4px 12px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.white};
  ${typographyPreset1};
  width: 140px; 
  
  @media (max-width: 768px) {
    ${typographyPreset10};
    max-width: 122px;
    width: 100%;
    white-space: nowrap;
  }
`;

export const Title = styled.h2`
  ${typographyPreset4};
  
  span {
    ${typographyPreset3};
  }
  
  @media (max-width: 768px) {
    padding: 0 20px;
    ${typographyPreset9};
    
    span {
      ${typographyPreset22};
    }
  }
`;

export const Description = styled.p`
  ${typographyPreset16};
  color: ${({ theme }) => theme.palette.gray500};
  max-width: 575px;
  width: 100%;
  
  @media (max-width: 768px) {
    ${typographyPreset14};
    max-width: 90%;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-top: 30px;
  
  @media (max-width: 768px) {
    margin-top: 48px;
    gap: 40px;
  }
`;

export const SectionSteps = styled.div`
  width: 100%;
  max-width: 550px;
  margin-left: 50px;
`;

export const SectionStepsTitle = styled.div`
  ${typographyPreset18};
  color: ${({ theme }) => theme.palette.gray900};
`;

export const SectionFormTitle = styled.div`
  ${typographyPreset10};
  color: ${({ theme }) => theme.palette.black};
`;

export const SectionFormMail = styled.div`
  ${typographyPreset10};
  display: grid;
  color: ${({ theme }) => theme.palette.black};
  max-width: 400px;
  width: 100%;
  justify-items: center;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  grid-gap: 11px;
  
  input { 
    width:  295.16px;
    padding: 9px 18px;
    border-radius: 929.053px;
    border: 1px solid rgba(122, 122, 122, 0.1);
  }
  
  img {
    margin-top: 10px;
  }
  
  button {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  gap: 48px;
  justify-content: space-between;

  &:nth-child(2n){
    flex-direction:row-reverse;
  }
  
  img {
    max-width: 587px !important;
    height: auto;
  }

  @media (max-width: 1070px) {
    display: flex;
    flex-direction: column;
    img {
      max-width: 343px !important;
    }

    &:nth-child(2n) {
      flex-direction: column;
    }
  }
  
  @media (max-width: 768px) {
    &:nth-child(2n) {
      flex-direction: column;
    }

    max-width: 340px;
    gap: 16px;
  }
`;

export const FirstImage = styled.div`
  display: none;

  @media (max-width: 1070px) {
    display: block;
  }
`;

export const LastImage = styled.div`
  @media (max-width: 1070px) {
    display: none;
  }
`;

export const StepDesc = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const StepTitle = styled.h4`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: ${({ theme }) => theme.palette.gray900};

  span {
    display: inline-block;
    margin-right: 3px;
  }
  
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    
    span {
      margin-right: 2px;
    }
  }
`;

export const StepDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.palette.gray500};
  
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    max-width: 70%;
  }
`;
