import styled from "styled-components";
import {typographyPreset17, typographyPreset19} from "../../../styles/typography";

export const SectionCardWrapper = styled.div`
  max-width: calc(33% - 13px);
  width: 100%;
  background: #FCF8F2;
  border: 1px solid rgba(122, 122, 122, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: min-content;
  align-self: stretch;
  
  @media (max-width:1100px) {
    max-width: 575px;
    width: 100%;
    padding: 20px;
    max-height: 240px;
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const UserAvatar = styled.div``;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.p`
  ${typographyPreset19};
  
  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
`;

export const UserJob = styled.p`
  ${typographyPreset17};
  margin-top: 4px;
  
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
  }
`;

export const UserMessage = styled.p`
  ${typographyPreset17};
  text-align: left;
  margin-top: 12px;
  margin-left: 72px;
  
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    height: 95px;
    overflow: hidden;
    color: ${({ theme }) => theme.palette.gray500};
  }
`;

export const UserAccounts = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 72px;
`;