import React, { useState } from 'react';

//styles and icons
import * as S from './PreFooterSection.styled';
import ArrowSVG from '../../assets/images/sectionEndArrow.svg';

//type  and constants
import PreFooterSectionI from './PreFooterSection.type';
import { Button, Image } from '../../components';
//import FacebookIconSVG from '../../assets/images/facebookIcon.svg';
//import TwitterIconSVG from '../../assets/images/twitterIcon.svg';
//import AppleIconSVG from '../../assets/images/apple.svg';
// import button from "../../components/button/button";

const APP_URL = process.env.GATSBY_REACT_APP_URL;

const PreFooterSection = ({
    sectionDescription,
    sectionTitle,
    form,
}: PreFooterSectionI) => {
    const [username, setUsername] = useState('');

    const handleOnChangeUsername = (e: any) => {
        setUsername(e.target.value);
    };
    return (
        <S.PreFooterWrapper>
            <S.HomeSectionInnerWrapper>
                <img src={ArrowSVG} alt="background-arrow" />
                <S.InfoSection>
                    <S.Title
                        dangerouslySetInnerHTML={{ __html: sectionTitle }}
                    />
                    <S.Description>{sectionDescription}</S.Description>
                </S.InfoSection>
                <S.CreateDigestInfo>
                    <S.SendYourEmail>
                        <input
                            type={'text'}
                            placeholder={form.placeholder}
                            onChange={handleOnChangeUsername}
                            value={username}
                        />
                        <Button
                            link={`${APP_URL}/signup?name=${username}`}
                            target="_blank"
                        >
                            Sign up
                        </Button>
                    </S.SendYourEmail>
                    <S.SocialButtons>
                        {form.socials &&
                            form.socials.map((item, index) => (
                                <Button
                                    key={index}
                                    type={'secondary'}
                                    link={item.link}
                                    target="_blank"
                                >
                                    <Image
                                        altText={'digest-social-button'}
                                        localFile={{
                                            publicURL:
                                                item.icon.localFile.publicURL,
                                        }}
                                    />
                                </Button>
                            ))}
                    </S.SocialButtons>
                </S.CreateDigestInfo>
            </S.HomeSectionInnerWrapper>
        </S.PreFooterWrapper>
    );
};

export default PreFooterSection;
