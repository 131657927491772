import styled from "styled-components";
import {typographyPreset2, typographyPreset23, typographyPreset14, typographyPreset8, typographyPreset9, typographyPreset20} from "../../styles";

export const PrivacyWrapper = styled.div`
    margin-bottom: 97px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 16px;
`;

export const SubBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px 0 48px;
    max-width: 720px;
    width: 100%;
`;

export const Timestamp = styled.p`
    ${typographyPreset2};
    color: ${({ theme }) => theme.palette.gray600};
`
export const Title = styled.h1`
    padding: 12px 0 24px;
    ${typographyPreset23};
    color: ${({ theme }) => theme.palette.gray900};
`

export const Description = styled.p`
    padding-bottom: 40px;
    ${typographyPreset14};
    color: ${({ theme }) => theme.palette.gray500};
    text-align: center;
`

export const WebDescription = styled.p`
    ${typographyPreset8};
    color: ${({ theme }) => theme.palette.gray500};
`

export const InfoWrapper = styled.div`
    max-width: 720px;
    width: 100%;
`;

export const InfoBlock = styled.div`
    margin: 48px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const InfoBlockTitle = styled.h2`
    color: ${({ theme }) => theme.palette.gray900};
    ${typographyPreset9}
    text-align: left;
    padding-bottom: 24px;
`

export const InfoBlockDescription = styled.p`
    ${typographyPreset8};
    color: ${({ theme }) => theme.palette.gray500};

`

export const SubInfoBlock = styled.div`
    margin: 48px 0;
`
export const SubInfoBlockTitle = styled.h2`
    padding-bottom: 16px;
    ${typographyPreset20};
    color: ${({ theme }) => theme.palette.gray900};`;

export const SubInfoBlockDescription = styled.p`
    ${typographyPreset8};
    color: ${({ theme }) => theme.palette.gray500};
`;
