import { NoSection } from '../sections';
import { OpportunitySection } from '../sections/OpportunitySection';
import { IntroSection } from '../sections';
import { ValuesSection } from '../sections';
import { PrivacySection } from '../sections/PrivacySection';
import { ContactSection } from '../sections/ContactSection';
import { MailboxSection } from '../sections/MailboxSection';
import { SourcesSection } from '../sections/SourcesSection';
import { TestimonialsSection } from '../sections/TestimonialsSection';
import { PreFooterSection } from '../sections/PreFooterSection';
import { TextPageSection } from '../sections/TextPageSection';

const getSectionComponent = (name: string): ((props: any) => JSX.Element) => {
    const sectionName = name?.split('_').pop() || '';

    switch (sectionName) {
        case 'IntroSection':
            return IntroSection;
        case 'ValuesSection':
            return ValuesSection;
        case 'OpportunitySection':
            return OpportunitySection;
        case 'PrivacySection':
            return PrivacySection;
        case 'ContactSection':
            return ContactSection;       
        case 'MailboxSection':
            return MailboxSection;
        case 'SourcesSection':
            return SourcesSection;       
        case 'TestimonialsSection':
            return TestimonialsSection;
        case 'PreFooterSection':
            return PreFooterSection;             
        case 'TextPageSection':
            return TextPageSection;       
        default:
            return NoSection;
    }
};

export default getSectionComponent;
