import styled from "styled-components";
import {typographyPreset1, typographyPreset16, typographyPreset3, typographyPreset4} from "../../styles";
import {typographyPreset18} from "../../styles/typography";

export const SourcesSectionWrapper = styled.div`
  padding: 120px 112px;
  background: ${({ theme }) => theme.palette.sectionTwoBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  
  & > img {
    position: absolute;
    right: 0;
    top: 280px;
  }

  .arrow-mobile {
    display: none;
  }
  
  .button-add-source {
    max-width: 157px;
    width: 100%;
    margin-top: 32px;
  }

  @media (max-width: 1070px) {
    .arrow-mobile {
        display: block;
    }

    .arrow {
        display: none;
    }
  }
  
  @media (max-width: 768px) {
    padding: 80px 16px;
    justify-content: center;
    align-items: center;
    gap: 0;

    & > img {
      max-width: 102.27px;
      top: 20px;
      height: auto;
    }
    
    .button-add-source {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const Values = styled.div`
  padding: 4px 12px;
  border-radius: 32px;
  background: ${({ theme }) => theme.palette.white};
  ${typographyPreset1};
  width: 87px;
  
  @media (max-width: 768px) {
    max-width: 76px;
    margin:auto;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
`;

export const Title = styled.h2`
  ${typographyPreset4};
  text-align: left;
  
  span {
    ${typographyPreset3};
  }
  
  @media (max-width: 768px) {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    width: 70%;
    margin:auto;
    margin-top: 12px;
    
    span {
      font-size: 30px;
      font-style: italic;
      font-weight: 500;
      line-height: 35px;
      text-align: center;
    }
  }
`;

export const Description = styled.p`
  ${typographyPreset16};
  max-width: 768px;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.palette.gray500};
  
  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    width: 95%;
    margin-top: 16px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-top: 64px;
  gap: 10px;
  
  @media (max-width: 768px) {
    display: grid;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 48px;
  }
`;

export const SourcesTitle = styled.p`
  ${typographyPreset18};
  color: ${({ theme }) => theme.palette.gray500};
  
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color:#000;
    margin-top: 24px;
    margin-bottom: 10px;
  } 
`;

export const IconItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 80px;
  
  @media (max-width: 768px) {
    img {
      width: 55px;
      height: 60px;
    }
  }
`;

export const Container = styled.div`
  width:100%;
  max-width:1218px;
  margin:auto;
`