import styled from "styled-components";

export const RedditSourcePost = styled.div`
width: calc(50% - 10px);

@media (max-width: 1250px) {
    width:100%;
  }
`;

export const RedditSourceTitle = styled.div`
    display: block;
    font-weight: 500;
    color: #272828;
    margin-bottom: 8px;
    text-align: left;
`

export const RedditSourceMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 14px;
    color: #656766;
`

export const RedditSourceItem = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`

export const RedditSourceImage = styled.img`
  width:100%;
  margin-top:15px;
  object-fit:cover;
`