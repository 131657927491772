import styled from "styled-components";

export const StripeSourceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.white};
    margin-bottom:35px;
`;

export const StripeSourceHeader = styled.div`
    display: flex;
    justify-content:flex-start;
    align-items:center;
    margin-bottom:15px;
    margin-right:auto;
`

export const StripeSourceName = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 10px;
    gap:10px;
    margin-bottom: 20px;

    img{
        max-width: 30px;
    }
`

export const StripeSourceIcon = styled.div`
svg{
  margin-right: 16px;
  margin-bottom: -12px;
}
`
export const StripeSourceTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    text-align: left;
`

export const StripeSourceContainer = styled.div`
    display: flex;
    width:100%;
    flex-wrap: wrap;
    justify-content: baseline;
    gap: 15px;
`