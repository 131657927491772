import React from 'react';
import * as S from './YoutubeVideo.styled';
import IYoutubeVideo from './YoutubeVideo.type';

const YoutubeVideo = ({ title, image, date }: IYoutubeVideo) => {
    return (
        <S.YoutubeVideo>
            <S.YoutubeVideoTitle>{title}</S.YoutubeVideoTitle>
            <S.YoutubeVideoDate>{date}</S.YoutubeVideoDate>
            <S.YoutubeVideoImage src={image} />
        </S.YoutubeVideo>
    );
};

export default YoutubeVideo;
