import styled from 'styled-components';

export const StyledMenu = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background: ${({ theme }) => theme.palette.white};
  height: 384px;
  width: 100vw;
  text-align: left;
  padding: 36px 36px 24px;
  position: absolute;
  bottom: 100%; 
  right: 0;
  z-index: 20;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateY(120%)' : 'translateY(0)'};

  nav  {
    margin-bottom: 62px;
    
    ul {
      display: flex;
      flex-direction: column;
      gap: 32px;
      
      a {
        text-transform: capitalize;
        letter-spacing: 0.5px;
        padding: 0;
      }
    }
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    & > button {
      text-align: center;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.palette.black};
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.gray300};
    }
  }
`;