import styled from "styled-components";
import {typographyPreset17} from "../../styles";

export const FooterWrapper = styled.div`
  padding: 34px 112px;
  border-bottom: 1px solid #FEF3F2;
  background: #D7E1DC;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr;
  align-items: center;
  
  @media (max-width: 1070px) {
    padding: 80px 32px;
  }
  
  @media (max-width: 768px) {
    padding: 48px 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
    
    & > nav > ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
       
      & > * {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;
      }
    }
  }
`;

export const Logo = styled.div`
  @media (max-width: 768px) {
    max-width: 78px;
  }
`;

export const AuthButtons = styled.div`
  display: flex;
  gap: 12px;
`;

export const Certificate = styled.p`
  // ${typographyPreset17};
  
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    width: 100%;
    border-top: 1px solid rgba(122, 122, 122, 0.1);
    padding-top: 32px;
    color:#656766;
  }
`;