import styled from "styled-components";

export const CalendarSourceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.white};
    margin-bottom:35px;
`;

export const CalendarSourceHeader = styled.div`
    display: flex;
    justify-content:flex-start;
    align-items:center;
    margin-bottom:15px;
    margin-right:auto;
`

export const CalendarSourceIcon = styled.div`
svg{
  margin-right: 16px;
  margin-bottom: -12px;
}
`
export const CalendarSourceTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    text-align: left;
`

export const CalendarSourceMain = styled.div`
    width:100%;
`

export const CalendarSourceDateSetting = styled.div`
    font-weight: 500;
    margin-bottom: 8px;
`

export const CalendarSourceDatePeriod = styled.div`
    font-weight: 500;
    color: #828584;
    margin-bottom: 16px;
`

export const CalendarSourceLine = styled.div`
    width: 100%;
    background: #eaeceb;
    height: 1px;
    margin-bottom: 16px;
`

export const CalendarSourceDay = styled.div`

`

export const CalendarSourceDayInfo = styled.div`
font-weight: 500;
    margin-bottom: 10px;
`
export const CalendarSourceDayEvent= styled.div`
display: block;
    text-align: left;
    margin-bottom: 16px;
    border-left: 2px solid #c776c9;
    padding-left: 12px;
`

export const CalendarSourceDayEventName= styled.div`
font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;

`
export const CalendarSourceDayEventDetails= styled.div`


    font-size: 14px;
    color: #828584;
`