import React from 'react';

//styles and icons
import * as S from './Navbar.styled';

//type  and constants
import NavbarI from './Navbar.type';
import { Link } from 'gatsby';

const Navbar: React.FC<NavbarI> = ({ menuItems }) => {
    return (
        <S.NavbarWrapper>
            <S.NavList>
                {menuItems &&
                    menuItems.map((item) => (
                        <Link key={item.label} to={item.url}>
                            <S.NavItem>{item.label}</S.NavItem>
                        </Link>
                    ))}
            </S.NavList>
        </S.NavbarWrapper>
    );
};

export default Navbar;
