import React from 'react';
import * as S from './InstagramSourcePost.styled';
import IInstagramSourcePost from './InstagramSourcePost.type';

const InstagramSourcePost = ({
    fullUsername,
    username,
    avatar,
    content,
    media,
    likes,
    comments,
    date,
}: IInstagramSourcePost) => {
    return (
        <S.InstagramSourcePost>
            <S.InstagramSourcePostWrapper>
                <S.InstagramSourcePostUser>
                    <S.InstagramSourcePostAvatar src={avatar} />
                    <div>
                        <S.TwitterNameBadgeWrapper>
                            <S.InstagramSourcePostName>
                                {fullUsername}
                            </S.InstagramSourcePostName>
                            <svg
                                width={18}
                                hanging={18}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 22 22"
                            >
                                <path
                                    fill="#1d9bf0"
                                    d="M20.396 11a3.487 3.487 0 0 0-2.008-3.062 3.474 3.474 0 0 0-.742-3.584 3.474 3.474 0 0 0-3.584-.742A3.468 3.468 0 0 0 11 1.604a3.463 3.463 0 0 0-3.053 2.008 3.472 3.472 0 0 0-1.902-.14c-.635.13-1.22.436-1.69.882a3.461 3.461 0 0 0-.734 3.584A3.49 3.49 0 0 0 1.604 11a3.496 3.496 0 0 0 2.017 3.062 3.471 3.471 0 0 0 .733 3.584 3.49 3.49 0 0 0 3.584.742A3.487 3.487 0 0 0 11 20.396a3.476 3.476 0 0 0 3.062-2.007 3.335 3.335 0 0 0 4.326-4.327A3.487 3.487 0 0 0 20.396 11zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"
                                />
                            </svg>
                        </S.TwitterNameBadgeWrapper>
                        <S.InstagramSourcePostUsername>
                            {username}
                        </S.InstagramSourcePostUsername>
                    </div>
                </S.InstagramSourcePostUser>
                <S.InstagramSourceImg src={media} width={300} height={300} />
                <S.InstagramSourcePostContent
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                <S.InstagramSourcePostMeta>
                    <S.InstagramSourcePostItem>
                        <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.24725 0.794922C7.27987 0.794922 6.45325 1.48446 5.9991 1.95985C5.54494 1.48446 4.72017 0.794922 3.75325 0.794922C2.08663 0.794922 0.923096 1.95661 0.923096 3.61954C0.923096 5.45185 2.36817 6.63615 3.76617 7.78169C4.42617 8.32308 5.10925 8.88246 5.6331 9.50277C5.72125 9.60661 5.85048 9.66661 5.98617 9.66661H6.01294C6.1491 9.66661 6.27787 9.60615 6.36556 9.50277C6.89033 8.88246 7.57294 8.32261 8.2334 7.78169C9.63094 6.63661 11.0769 5.45231 11.0769 3.61954C11.0769 1.95661 9.9134 0.794922 8.24725 0.794922Z"
                                fill="#656766"
                            ></path>
                        </svg>
                        {likes}
                    </S.InstagramSourcePostItem>
                    <S.InstagramSourcePostItem>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="10"
                            fill="none"
                        >
                            <path
                                fill="#828584"
                                d="M6 .5C3.1.5.75 2.37.75 4.679c0 .996.439 1.908 1.169 2.625C1.663 8.317.805 9.22.795 9.23a.16.16 0 0 0 .119.271c1.36 0 2.379-.639 2.883-1.033A6.37 6.37 0 0 0 6 8.857c2.9 0 5.25-1.87 5.25-4.178C11.25 2.37 8.9.5 6 .5Z"
                            ></path>
                        </svg>
                        {comments}
                    </S.InstagramSourcePostItem>
                    <S.InstagramSourcePostItem>
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.74805 0.777588C2.74805 0.702931 2.71839 0.631332 2.6656 0.578541C2.61281 0.525751 2.54121 0.496094 2.46655 0.496094C2.3919 0.496094 2.3203 0.525751 2.26751 0.578541C2.21472 0.631332 2.18506 0.702931 2.18506 0.777588V1.05908H1.62207C1.32344 1.05908 1.03705 1.17771 0.825885 1.38887C0.614723 1.60003 0.496094 1.88643 0.496094 2.18506L0.496094 2.74805H9.50391V2.18506C9.50391 1.88643 9.38528 1.60003 9.17412 1.38887C8.96295 1.17771 8.67656 1.05908 8.37793 1.05908H7.81494V0.777588C7.81494 0.702931 7.78528 0.631332 7.73249 0.578541C7.6797 0.525751 7.6081 0.496094 7.53345 0.496094C7.45879 0.496094 7.38719 0.525751 7.3344 0.578541C7.28161 0.631332 7.25195 0.702931 7.25195 0.777588V1.05908H2.74805V0.777588ZM9.50391 8.37793V3.31104H0.496094V8.37793C0.496094 8.67656 0.614723 8.96295 0.825885 9.17412C1.03705 9.38528 1.32344 9.50391 1.62207 9.50391H8.37793C8.67656 9.50391 8.96295 9.38528 9.17412 9.17412C9.38528 8.96295 9.50391 8.67656 9.50391 8.37793Z"
                                fill="#656766"
                            ></path>
                        </svg>
                        {date}
                    </S.InstagramSourcePostItem>
                </S.InstagramSourcePostMeta>
            </S.InstagramSourcePostWrapper>
        </S.InstagramSourcePost>
    );
};

export default InstagramSourcePost;
