import React from 'react';
import ISourcesIcons from './SourcesIcons.type';
import * as S from './SourcesIcons.styled';

const SourcesIcons = ({ builderSources, addSource }: ISourcesIcons) => {
    console.log(builderSources);

    return (
        <S.SourceIcons>
            {builderSources.map((item) => (
                <>
                    <S.SourceIconsCategory>
                        {item.category}
                    </S.SourceIconsCategory>
                    <S.SourceIconsRow>
                        {item.icons.map((icon) => (
                            <S.SourceIconsWrapper
                                onClick={() => addSource(icon.urlName)}
                            >
                                <S.SourceIconsImage
                                    src={icon.icon.localFile.publicURL}
                                    alt={icon.icon.altText}
                                />
                                <S.SourceIconsName>
                                    {icon.name}
                                </S.SourceIconsName>
                            </S.SourceIconsWrapper>
                        ))}
                    </S.SourceIconsRow>
                </>
            ))}
        </S.SourceIcons>
    );
};

export default SourcesIcons;
