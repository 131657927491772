import styled from "styled-components";

export const ProductHunt = styled.div`
    width: calc(50% - 10px);
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
    margin-bottom: 10px;

    @media (max-width: 1250px) {
        width:100%;
    }
`;

export const ProductHuntImage = styled.img`
display: block;
    max-width: 84px;
    max-height: 84px;
`


export const ProductHuntMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 14px;
    color: #656766;
`

export const ProductHuntItem = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`

export const ProductHuntTitle = styled.div`
font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #272828;
    margin-bottom: 8px;
`

export const ProductHuntDescription = styled.div`
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #272828;
`

export const ProductHuntContentWrapper = styled.div`
    display: block;
    flex:1;
`