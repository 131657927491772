import styled from "styled-components";

export const TwitterSourceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    width:100%;
    background: ${({ theme }) => theme.palette.white};
    margin-bottom:35px;
`;


export const TwitterSourceHeader = styled.div`
    display: flex;
    justify-content:flex-start;
    align-items:center;
    margin-bottom:15px;
    margin-right:auto;
`

export const TwitterSourceIcon = styled.div`
svg{
  margin-right: 16px;
  margin-bottom: -12px;
}
`
export const TwitterSourceTitle = styled.div`
font-size: 20px;
    font-weight: 500;
    text-align: left;
`

export const TwitterSourceRow= styled.div`
display:flex;
flex-wrap:wrap;
gap:15px;
`