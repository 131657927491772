import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import * as S from './Textarea.styled';
import { TextareaProps } from './types';

const Textarea: React.FC<TextareaProps> = ({
  id,
  name,
  placeholder,
  label = '',
  children,
  className,
  showError = true,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<Record<string, string>>();
  const { field } = useController({ name, control });

  const errorMessage = errors[field.name]?.message;
  const isError = Boolean(errorMessage) && showError;

  return (
    <div className={className}>
      <S.Top $error={isError}>
        <label>{label}</label>
        {isError && <span>{errorMessage}</span>}
      </S.Top>
      <S.Textarea
        {...rest}
        name={id}
        placeholder={placeholder}
        value={field.value || ''}
        onChange={field.onChange}
        $error={isError}
      />
    </div>
  );
};

export default Textarea;
