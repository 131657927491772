import styled from "styled-components";
import {
  typographyPreset11, typographyPreset12, typographyPreset13,
  typographyPreset16,
  typographyPreset3,
  typographyPreset4, typographyPreset9
} from "../../styles";
import EmailSVG from "../../assets/images/whiteEmail.svg";

export const PreFooterWrapper = styled.div`
  background: ${({ theme }) => theme.palette.white};
  width: 100%;
  padding: 0 112px 120px 112px;
  display: flex;
  justify-content: center; 
  
  @media (max-width: 1100px) {
    padding: 80px 16px;
  }

  @media (max-width: 992px) {
    padding-top:0;
  }
`;

export const HomeSectionInnerWrapper = styled.div`
  background: #000000;
  border-radius: 12px;
  width: 100%;
  max-width: 1216px;
  display: flex;
  position: relative;
  padding: 104px 80px;
  justify-content: center;
  align-items: center;
  gap: 141px;

  & > img {
    position: absolute;
    left: 220px;
    bottom: 0;
  }
  
  @media (max-width: 1100px) {
    padding: 48px 16px;
    display: flex;
    flex-direction: column;
    gap: 0;
    
    & > img {
      width: 380px;
      height: auto;
      left: -120px;
      bottom: 0;
    }
  }
`;

export const Title = styled.h2`
  ${typographyPreset4};
  text-align: left;
  color: ${({ theme }) => theme.palette.white};
  
  span {
    ${typographyPreset3};
  }
  
  @media (max-width: 1100px) {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    margin-top: 12px;
    margin-bottom: 24px;
    width: 100%;
    text-align: left;

    span {
      font-size: 30px;
      font-style: italic;
      font-weight: 500;
      line-height: 35px;
      text-align: center;
    }
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Description = styled.p`
  ${typographyPreset16};
  max-width: 400px;
  width: 100%;
  text-align: left;
  line-height: 23px;
  color: ${({ theme }) => theme.palette.gray300};
  
  @media (max-width: 1100px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    width: 80%;
    margin-bottom: 48px;
  }
`;

export const CreateDigestInfo = styled.div`
      @media (max-width: 575px) {
        width:100%;
      }
`;

export const CreateDigestInfoTitle = styled.h3`
  ${typographyPreset11};
  font-style: italic;
  
  span {
    font-style: normal;
    ${typographyPreset9};
  }
`;

export const CreateDigestInfoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
`;

export const PeopleInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const PeopleInfoTitle = styled.div`
  ${typographyPreset13};
  span {
    ${typographyPreset12};
  }
`;

export const SendYourEmail = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  margin-top: 44px;
  gap: 12px;
  
  input {
    width: 288px;
    height: 48px;
    padding: 28px 31px 23px 50px;
    border-radius: 1000px;
    background-image: url(${EmailSVG});
    background-position: 22px 17px;
    background-repeat: no-repeat;
    background-color: #282828;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #B1B4B2;
    
    ::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #B1B4B2;
    }
  }
  
  button {
    max-width: 138px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap !important;
  }

  @media (max-width: 1100px) {
    margin-top: 0;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      padding: 13px 23px 13px 53px;
      height: auto;

      ::placeholder {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }
    }

    button {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  border: none;
  
  button, a {
    max-width: 138px;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #434343;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 64px;
  }
  
  @media (max-width: 1100px) {
    margin-bottom: 94px;
  }
`;
