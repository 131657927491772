import styled from "styled-components";

export const HeaderWrapper = styled.div<{ open: boolean }>`
  padding: 34px 112px;
  border-bottom: 1px solid #FEF3F2;
  background: ${({ open }) => open ? 'white' : '#FFFCF4'};
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  position: relative;
  
  @media (max-width: 960px) {
    padding: 34px 60px;
  }
  
  @media (max-width: 768px) {
    display: block;
    padding: 20px 16px;
  }
`;

export const BurgerWrapper = styled.div`
  display: none;
  @media(max-width: 768px) {
    display: block;
  }
`;

export const NavbarWrapper = styled.div`
  @media(max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.div`
`;

export const AuthButtonsWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AuthButtons = styled.div`
  display: flex;
  gap: 12px;
  height: 40px;
  
  button {
    white-space: nowrap;
  }
`;