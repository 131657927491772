import styled from 'styled-components';
import { typographyPreset10, typographyPreset8 } from '../../styles';

export const Input = styled.input<{ $error: boolean }>`
  ${typographyPreset8};
  max-width: 480px;
  width: 100%;
  color: ${({ theme }) => theme.palette.gray700};
  border: 1px solid ${({ theme, $error }) => theme.palette[$error ? 'red' : 'gray300']};
  border-radius: 32px;
  padding: 17px;

  ::placeholder {
    color: ${({ theme }) => theme.palette.gray500};
  }
`;

export const Top = styled.label<{ $error: boolean }>`
  ${typographyPreset10};
  display: flex;
  cursor:pointer;
  justify-content: space-between;
  color: ${({ theme, $error }) => theme.palette[$error ? 'red' : 'gray700']};
  margin-bottom: 4px;
`;

export const CheckboxField = styled.div<{ $error: boolean}>`
  height:20px;
  width:20px;
  border-radius:6px;
  position:relative;
  margin-right:12px;
  cursor:pointer;


  &:before {
    content:'';
    display:inline-block;
    cursor: pointer;
    border-radius: 6px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    width: 20px;
    border: 1px solid ${({ theme, $error }) => theme.palette[$error ? 'red' : 'gray300']};

  }

  &:after{
    content:'';
    display:none;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: solid ${({theme}) => theme.palette.gold};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

`;

export const InputCheckbox = styled.input<{ $error: boolean}>`
    ${typographyPreset8};

    color: ${({ theme }) => theme.palette.gray700};
    border: 1px solid ${({ theme, $error }) => theme.palette[$error ? 'red' : 'gray300']};
    ::placeholder {
      color: ${({ theme }) => theme.palette.gray500};
    }
    :checked ~ ${CheckboxField}::after{
      display:block;
    }
    :checked ~ ${CheckboxField}::before{
      border: solid ${({theme}) => theme.palette.gold};
      background: #fdf0cf;

    }

`;

export const InputCheckboxWrapper = styled.label`
${typographyPreset10};
    display:flex;
    align-items:center;
    font-weight:400;
    cursor:pointer;
    label{
      margin-bottom:0px;
    }

    a{
      text-decoration:underline;
    }

`;
