import styled from "styled-components";
import { typographyPreset8, typographyPreset9 } from "../../styles";

export const ContactWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0; 

    @media (max-width: 575px) {
        margin-bottom:64px;
    }
`;

export const ContactInnerWrapper = styled.div`
    max-width: 480px;
    width: 100%;
    padding: 0 16px;
`;

export const Title = styled.h1`
    ${typographyPreset9}

    @media (max-width: 575px) {
        text-align:left;
    }
`;

export const Description = styled.p`
    ${typographyPreset8}
    color: ${({ theme }) => theme.palette.gray500};
    text-align: center;
    margin-top: 12px;

    @media (max-width: 575px) {
        text-align:left;
    }
`;

export const ContactFormWrapper = styled.div`
    margin-top: 48px;
`;