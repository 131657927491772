import React from 'react';

//other components

//styles and icons
import * as S from './SectionCard.styled';

//type  and constants
import SectionCardI from './SectionCard.type';

const SectionCard = ({ id, title, description, isActive }: SectionCardI) => {
    return (
        <S.SectionCardWrapper isActive={isActive}>
            <S.CardBar>
                <S.CardBarProgress />
            </S.CardBar>
            <S.SectionCardInnerWrapper>
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
            </S.SectionCardInnerWrapper>
        </S.SectionCardWrapper>
    );
};

export default SectionCard;
