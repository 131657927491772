export const cn = (...args: (string | boolean | undefined)[]): string =>
    args.filter((c) => c !== (undefined || false)).join(' ');

export const isUrlInternal = (url: string) => /^\/(?!\/)/.test(url);

export const findInText = (text: string, search: string): boolean =>
    text.toLowerCase().includes(search.toLowerCase().trim());

export const setBodyOverflow = (value: 'hidden' | 'visible' | '') =>
    (document.body.style.overflow = value);

export const isInViewport = (element : any) => {
        const rect = element.getBoundingClientRect();
        const windowHeight = window ? window.innerHeight * 0.25 : 0
        return (
            (rect.top ) >= 0 &&
            (rect.bottom + windowHeight) <= (window && window.innerHeight || document.documentElement.clientHeight)
        );
}