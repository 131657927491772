import React from 'react';
import BurgerI from "./Burger.type";
import { StyledBurger } from './Burger.styled';

const Burger: React.FC<BurgerI> = ({ open, setOpen }) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

export default Burger;