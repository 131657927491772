import styled from 'styled-components';

import { Button } from '../../Button';

export const ContactFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ContactButton = styled(Button)`
  margin-top: 5px;
  text-align: center;
`;

export const Textarea = styled.textarea`
  resize: none;

`;

export const Message = styled.div`
  text-align:center;
`