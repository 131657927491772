import { FormField } from '../../../types/formTypes';

import { ContactFormShape } from './types';

export const DefaultValues: ContactFormShape = {
  name: '',
  email: '',
  phone: '',
  message: '',
  agreement: false,
};

export const ContactFormFields: FormField<keyof ContactFormShape> = {
  name: {
    id: 'contact.name',
    name: 'name',
    label: 'Your Name',
    placeholder: 'First name'
  },
  email: {
    id: 'contact.email',
    name: 'email',
    label: 'Email',
    placeholder: 'you@company.com',
  },
  phone: {
    id: 'contact.phone',
    name: 'phone',
    label: 'Phone number',
    placeholder: '+1 (555) 000-0000',
  },
  message: {
    id: 'contact.message',
    name: 'message',
    label: 'Message',
  },
  agreement: {
    id: 'contact.agreement',
    name: 'agreement',
    type: 'checkbox',
    label: 'You agree to our friendly <a href="/privacy">privacy policy</a>.',
  },
};

export const ContactSchemaKeys = Object.keys(ContactFormFields) as (keyof ContactFormShape)[];
