import styled from 'styled-components';

export const TiktokSourcePost = styled.div`
    width: calc(50% - 10px);

    @media (max-width: 1250px) {
        width: 100%;
    }
`;

export const TiktokSourcePostUser = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
`;

export const TiktokSourcePostAvatar = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 100%;
`;

export const TwitterNameBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const TiktokSourcePostName = styled.div`
    font-size: 14px;
`;
export const TiktokSourcePostUsername = styled.div`
    font-size: 12px;
    color: #656766;
`;

export const TiktokSourcePostContent = styled.div`
    overflow-wrap: break-word;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 15px;

    & a {
        color: rgb(246, 192, 53);
    }
`;

export const TiktokSourceImg = styled.img`
    max-width: 100%;
    border-radius: 6px;
    object-fit: cover;
    margin-bottom: 12px;
`;

export const TiktokSourcePostWrapper = styled.div`
    max-width: 100%;
    width: 100%;
    border: 1px solid #eaeceb;
    background: #fff;
    box-shadow: 24px 0px 56px rgba(52, 64, 84, 0.03);
    border-radius: 8px;
    padding: 20px;
`;

export const TiktokSourcePostMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 14px;
    color: #656766;
`;

export const TiktokSourcePostItem = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`;
