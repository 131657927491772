import React from 'react';

import ValuesSectionI from './ValuesSection.type';

import * as S from './ValuesSection.styled';

import { cardData } from './constants';
import { Card } from '../../components/Card';
import IIcon from '../../types/Icon.type';

const ValuesSection = (props: ValuesSectionI) => {
    const {
        pageName: sectionName,
        pageTitle: sectionTitle,
        pageDescription: sectionDescription,
        blocks: blocks,
    } = props;
    return (
        <S.ValuesSectionWrapper>
            <S.Values>Values</S.Values>
            <S.Title dangerouslySetInnerHTML={{ __html: sectionTitle }} />
            <S.Description>{sectionDescription}sdfasdfas</S.Description>
            <S.Cards>
                {blocks &&
                    blocks.map(
                        (item: {
                            icon: IIcon;
                            title: string;
                            description: string;
                        }) => (
                            <Card
                                icon={item.icon.localFile.publicURL}
                                title={item.title}
                                description={item.description}
                                key={item.title}
                            />
                        )
                    )}
            </S.Cards>
        </S.ValuesSectionWrapper>
    );
};

export default ValuesSection;
