import React from 'react';

//other components

//styles and icons
import * as S from './TestimonialsSection.styled';
import SectionSixArrowSVG from '../../assets/images/sectionSixArrow.svg';

//type  and constants
import TestimonialsSectionI from './TestimonialsSection.type';
import {
    cardDataOne,
    cardDataTwo,
    cardDataThree,
} from '../../sections/TestimonialsSection/constants';
import SectionCard from './SectionCard/SectionCard';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const TestimonialsSection = ({
    sectionDescription,
    sectionTitle,
    cards,
}: TestimonialsSectionI) => {
    return (
        <S.TestimonialsWrapper>
            <img src={SectionSixArrowSVG} alt={'arrow'} />
            <S.Values>Testimonials</S.Values>
            <S.Title dangerouslySetInnerHTML={{ __html: sectionTitle }} />

            <S.Description>{sectionDescription}</S.Description>
            <S.CardsWrapper>
                <S.Cards className="desktop-cards">
                    {cards &&
                        cards.map((item, index) => (
                            <SectionCard
                                key={index}
                                name={item.name}
                                avatar={item.avatar.localFile.publicURL}
                                jobTitle={item.title}
                                message={item.testimonial}
                                accounts={item.socials.map((item) => ({
                                    image: item.icon.localFile.publicURL,
                                    link: item.link,
                                }))}
                            />
                        ))}
                </S.Cards>

                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={30}
                    className="mySwiper"
                >
                    {cards &&
                        cards.map((item, index) => (
                            <SwiperSlide>
                                <SectionCard
                                    key={index + item.name}
                                    name={item.name}
                                    avatar={item.avatar.localFile.publicURL}
                                    jobTitle={item.title}
                                    message={item.testimonial}
                                    accounts={item.socials.map((item) => ({
                                        image: item.icon.localFile.publicURL,
                                        link: item.link,
                                    }))}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </S.CardsWrapper>
        </S.TestimonialsWrapper>
    );
};

export default TestimonialsSection;
