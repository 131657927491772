import React from 'react';
import PrivacySectionI from './PrivacySection.type';

//styles and icons
import * as S from './PrivacySection.styled';


const PrivacySection = ({
    sectionname,
    sectionnamedescription,
    timestamp,
    websitedescription,
    infoBlocks 
}: PrivacySectionI) => {
    console.log({ sectionname, sectionnamedescription, timestamp, websitedescription, infoBlocks })
    return (
        <S.PrivacyWrapper>
            <S.SubBlock>
                <S.Timestamp>{timestamp}</S.Timestamp>
                <S.Title>{sectionname}</S.Title>
                <S.Description>{sectionnamedescription}</S.Description>
                <S.WebDescription>{websitedescription}</S.WebDescription>
             </S.SubBlock>
            <S.InfoWrapper>
                {infoBlocks?.map((item: { infoBlockTitle: string; infoBlockDescription: string; subInfoBlocks: { subInfoBlockTitle: string; subInfoBlockDescription: string; }[] }) => {
                    return (
                    <S.InfoBlock key={`${item.infoBlockTitle}`}>
                        <S.InfoBlockTitle>{item.infoBlockTitle}</S.InfoBlockTitle>
                        <S.InfoBlockDescription>{item.infoBlockDescription}</S.InfoBlockDescription>
                        {item.subInfoBlocks?.map((subItem: { subInfoBlockTitle: string; subInfoBlockDescription: string; }) => (
                            <S.SubInfoBlock key={`${subItem.subInfoBlockTitle}-i`}>
                                <S.SubInfoBlockTitle>{subItem.subInfoBlockTitle}</S.SubInfoBlockTitle>
                                <S.SubInfoBlockDescription>{subItem.subInfoBlockDescription}</S.SubInfoBlockDescription>
                            </S.SubInfoBlock>
                        ))}
                    </S.InfoBlock>
                    );
                })}
            </S.InfoWrapper>
        </S.PrivacyWrapper>
    );
};

export default PrivacySection;
