import React from 'react';

//styles and icons
import * as S from './MailboxSection.styled';
import StepOneSVG from '../../assets/images/stepOneSubscribe.svg';
import StepTwoSVG from '../../assets/images/stepTwoSubscribe.svg';
import StepThreeSVG from '../../assets/images/steptThreeSubscribe.svg';

//type  and constants
import MailboxSectionI from './MailboxSection.type';
import Image from '../../components/Image/Image';

const MailboxSection = ({
    sectionDescription,
    sectionTitle,
    items,
}: MailboxSectionI) => {
    return (
        <S.MailboxSectionWrapper>
            <S.Mailbox>Digest mailbox</S.Mailbox>
            <S.Title
                dangerouslySetInnerHTML={{ __html: sectionTitle }}
            ></S.Title>
            <S.Description>{sectionDescription}</S.Description>
            <S.SectionWrapper>
                {items &&
                    items.map((item, index) => (
                        <S.StepContainer key={index}>
                            <Image
                                altText={'digest-form-step-one'}
                                localFile={{
                                    publicURL: item.image.localFile.publicURL,
                                }}
                            />
                            <S.StepDesc>
                                <S.StepTitle>{item.content.title}</S.StepTitle>
                                <S.StepDescription>
                                    {item.content.description}
                                </S.StepDescription>
                            </S.StepDesc>
                        </S.StepContainer>
                    ))}
            </S.SectionWrapper>
        </S.MailboxSectionWrapper>
    );
};

export default MailboxSection;
