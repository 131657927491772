import React from 'react';
import * as S from './DataSource.styled';
import dayjs from 'dayjs';

const DateSource = () => {
    const currentDay = dayjs().format('DD');
    const currentDayName = dayjs().format('dddd');
    const currrentDate = dayjs().format('MMMM, YYYY');

    return (
        <div>
            <S.DataSourceWrapper>
                <S.DataSourceDayName>{currentDayName}</S.DataSourceDayName>
                <S.DataSourceDayNumber>{currentDay}</S.DataSourceDayNumber>
                <S.DataSourceDate>{currrentDate}</S.DataSourceDate>
            </S.DataSourceWrapper>
        </div>
    );
};

export default DateSource;
