import styled from "styled-components";
import { fontFamily1, typographyPreset1 } from "../../styles";

export const NavbarWrapper = styled.nav`

`;

export const NavList = styled.ul`
  display: flex;
  gap: 32px;

`;

export const NavItem = styled.li`
  ${fontFamily1};
  ${typographyPreset1}
  
  &:hover {
    text-decoration: underline;
  }
`;