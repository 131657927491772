import React from 'react';
import LayoutI from './Layout.type';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Helmet from '../Helmet/Helmet';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../styles';

const Layout = ({
    children,
    thumbnail,
    mainMenu,
    footerMenu,
    settings,
}: LayoutI) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Helmet thumbnail={thumbnail} />
                <Header menuItems={mainMenu} settings={settings} />
                <main>{children}</main>
                <Footer menuItems={footerMenu} />
            </ThemeProvider>
        </>
    );
};

export default Layout;
