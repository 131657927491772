import axios from 'axios';


export type AxiosError = {
    response: {
        data: {
            status: number,
            message: string,
        }
    } 
}


const API_URL = process.env.BACKEND_API;


export const sendFormData = (data : any) =>{
    
    return axios
         .post(`${API_URL}/admin/messages`, JSON.stringify(data), {
           headers: { "Content-Type": "application/json; charset=UTF-8" }
         })
         .then((response) => console.log("repsonse", response.status)).catch((error) => {
            const err = error as AxiosError;
            throw new Error(err.response.data.message);
        });
   }


