import React from 'react';
import { StyledMenu } from './Menu.styled';
import MenuI from "./Menu.type";

const Menu: React.FC<MenuI> = ({ open, children }) => {
    return (
        <StyledMenu open={open}>
            { children }
        </StyledMenu>
    )
}
export default Menu;