import React from 'react';
import { FormProvider } from 'react-hook-form';
import { noop } from 'lodash';

import { FormProps } from './types';

const Form = <T extends Record<string, unknown>>({
    children,
    onSubmit = noop,
    formMethods,
}: FormProps<T>) => (
    <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>{children}</form>
    </FormProvider>
);

export default Form;
