import React, { useEffect, useState } from 'react';

//other components
import { Image, Navbar } from '../../components';
import { Link } from 'gatsby';

//styles and icons
import * as S from './Header.styled';
import LogoSVG from '../../assets/images/logo.svg';

//type  and constants
import HeaderI from './Header.type';
import { Button } from '../../components/Button';
import Menu from '../../components/Menu/Menu';
import Burger from '../../components/Burger/Burger';

const Header = ({ menuItems, settings }: HeaderI) => {
    const [open, setOpen] = useState(false);

    const { options: options } = settings;

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
        }
    }, [open]);

    return (
        <S.HeaderWrapper open={open}>
            <S.Logo>
                <Link to={'/'}>
                    <Image
                        altText={'Digest-logo'}
                        localFile={{ publicURL: LogoSVG }}
                    />
                </Link>
            </S.Logo>
            <S.BurgerWrapper>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen}>
                    <Navbar menuItems={menuItems} />
                    <S.AuthButtons>
                        <Button
                            type={'secondary'}
                            link={options.logInButton.url}
                        >
                            {options.logInButton.title}
                        </Button>
                        <Button link={options.signUpButton.url}>
                            {options.signUpButton.title}
                        </Button>
                    </S.AuthButtons>
                </Menu>
            </S.BurgerWrapper>
            <S.NavbarWrapper>
                <Navbar menuItems={menuItems} />
            </S.NavbarWrapper>
            <S.AuthButtonsWrapper>
                <S.AuthButtons>
                    <Button type={'secondary'} link={options.logInButton.url}>
                        {options.logInButton.title}
                    </Button>
                    <Button link={options.signUpButton.url}>
                        {options.signUpButton.title}
                    </Button>
                </S.AuthButtons>
            </S.AuthButtonsWrapper>
        </S.HeaderWrapper>
    );
};

export default Header;
