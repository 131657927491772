import React from 'react';
import { noop } from 'lodash';

import * as S from './Button.styled';
import { ButtonProps } from './Button.type';

const Button: React.FC<ButtonProps> = ({
    type = 'primary',
    children,
    className,
    buttonType = 'button',
    onClick = noop,
    disabled = false,
    link,
    target,
}) =>
    link ? (
        <S.StyledLink
            href={link}
            $type={type}
            type={buttonType}
            onClick={onClick}
            className={className}
            target={target}
        >
            {children}
        </S.StyledLink>
    ) : (
        <S.StyledButton
            $type={type}
            type={buttonType}
            onClick={onClick}
            disabled={disabled}
            className={className}
        >
            {children}
        </S.StyledButton>
    );

export default Button;
