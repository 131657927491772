import React, {
    useState,
    useEffect,
    useRef,
    createRef,
    MutableRefObject,
} from 'react';

//other components
import { SectionCard } from './SectionCard';
import MobileSlider from '../../components/MobileSlider';

//styles and icons
import * as S from './OpportunitySection.styled';
import ScreenMockupSVG from '../../assets/images/screenMockup.svg';
import StepTwoSectionThreeSVG from '../../assets/images/stepTwoSectionThree.svg';
import StepThreeSectionThreeSVG from '../../assets/images/stepThreeSectionThree.svg';
import PinkArrowSVG from '../../assets/images/pinkArrow.svg';
//type  and constants
import OpportunitySectionI from './OpportunitySection.type';
import { cardItems } from './constants';

//utils
import { isInViewport } from '../../utils';
import { useMediaQuery } from 'react-responsive';

const OpportunitySection = ({
    sectionDescription,
    cardItems,
    images,
    sectionTitle,
}: OpportunitySectionI) => {
    const [activeCard, setActiveCard] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    const breakpoint = 992;

    const responsive = useMediaQuery({
        query: `(max-width: ${breakpoint}px)`,
    });

    useEffect(() => {
        setIsMobile(responsive);
    }, [responsive]);

    const cardsRef = useRef<MutableRefObject<HTMLDivElement>[]>([]);

    cardsRef.current = cardItems.map(
        (_, index) => cardsRef.current[index] ?? createRef()
    );

    const checkRefs = () => {
        if (!isMobile) {
            cardsRef.current.forEach((item, index) => {
                if (item.current) {
                    if (isInViewport(item.current)) {
                        setActiveCard(index);
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (window !== undefined) {
            window.addEventListener('scroll', checkRefs);
            return () => window.removeEventListener('scroll', checkRefs);
        }
    }, []);

    return (
        <S.OpportunitySectionWrapper>
            <img src={PinkArrowSVG} alt="background-arrow" />
            <S.Container>
                <S.Values>Get the digest</S.Values>
                <S.Title dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                <S.Description>{sectionDescription}</S.Description>

                {isMobile ? (
                    <MobileSlider cardItems={cardItems} cardImages={images} />
                ) : (
                    <S.SectionWrapper>
                        <S.SectionInfoImage>
                            <img
                                src={
                                    images[activeCard].image.localFile.publicURL
                                }
                            />
                        </S.SectionInfoImage>
                        <S.SectionCardItems className="articles">
                            {cardItems.map(
                                (
                                    item: {
                                        id: number;
                                        title: string;
                                        description: string;
                                    },
                                    index
                                ) => (
                                    <S.CardWrapper
                                        key={item.id}
                                        className="article"
                                        ref={cardsRef.current[index]}
                                    >
                                        <SectionCard
                                            key={item.id}
                                            id={item.id}
                                            title={item.title}
                                            description={item.description}
                                            isActive={activeCard === index}
                                        />
                                    </S.CardWrapper>
                                )
                            )}
                        </S.SectionCardItems>
                    </S.SectionWrapper>
                )}
            </S.Container>
        </S.OpportunitySectionWrapper>
    );
};

export default OpportunitySection;
