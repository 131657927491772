import styled from "styled-components";


export const RSSSourcePost= styled.div`
    width:100%;
`;


export const RSSSourceTitle = styled.div`
    display: block;
    font-weight: 500;
    color: #272828;
    margin-bottom: 8px;
    text-align: left;
`

export const RSSSourceMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 14px;
    color: #656766;
`

export const RSSSourceItem = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`


export const RSSSourceContent= styled.div`
font-size: 15px;
line-height: 1.6;
margin-bottom: 8px;
`
