import React from 'react';

//other components

//styles and icons
import * as S from './SectionCard.styled';

//type  and constants
import SectionUserCardI from './SectionCard.type';
import Image from '../../../components/Image/Image';

const SectionCard = ({
    avatar,
    name,
    message,
    jobTitle,
    accounts,
}: SectionUserCardI) => {
    return (
        <S.SectionCardWrapper>
            <S.UserInfoWrapper>
                <S.UserAvatar>
                    <Image
                        altText={'digest-about-user-message-avatar'}
                        localFile={{ publicURL: avatar }}
                    />
                </S.UserAvatar>
                <S.UserInfo>
                    <S.UserName>{name}</S.UserName>
                    <S.UserJob>{jobTitle}</S.UserJob>
                </S.UserInfo>
            </S.UserInfoWrapper>
            <S.UserMessage>{message}</S.UserMessage>
            <S.UserAccounts>
                {accounts?.map((img: any) => (
                    <a href={img.link} target="_blank">
                        <Image
                            altText={'digest-about-user-message-avatar'}
                            localFile={{ publicURL: img.image }}
                        />
                    </a>
                ))}
            </S.UserAccounts>
        </S.SectionCardWrapper>
    );
};

export default SectionCard;
