import React from 'react';
import SingleStripeBlock from './SingleStripeBlock';
import * as S from './StripeSource.styled';

const StripeSource = () => {
    return (
        <S.StripeSourceWrapper>
            <S.StripeSourceHeader>
                <S.StripeSourceIcon>
                    <svg
                        width="41"
                        height="40"
                        viewBox="0 0 41 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g opacity="0.25" filter="url(#filter0_f_11035_408433)">
                            <rect
                                x="6.09473"
                                y="16.125"
                                width="29.0655"
                                height="18.1659"
                                rx="9.08297"
                                fill="#635BFF"
                            />
                        </g>
                        <g clip-path="url(#clip0_11035_408433)">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.25488 9.97989C5.25488 6.61957 5.25488 4.93941 5.90884 3.65594C6.48408 2.52697 7.40197 1.60908 8.53094 1.03384C9.81441 0.379883 11.4946 0.379883 14.8549 0.379883H27.1449C30.5052 0.379883 32.1854 0.379883 33.4688 1.03384C34.5978 1.60908 35.5157 2.52697 36.0909 3.65594C36.7449 4.93941 36.7449 6.61957 36.7449 9.97988V22.2699C36.7449 25.6302 36.7449 27.3104 36.0909 28.5938C35.5157 29.7228 34.5978 30.6407 33.4688 31.2159C32.1854 31.8699 30.5052 31.8699 27.1449 31.8699H14.8549C11.4946 31.8699 9.81441 31.8699 8.53094 31.2159C7.40197 30.6407 6.48408 29.7228 5.90884 28.5938C5.25488 27.3104 5.25488 25.6302 5.25488 22.2699V9.97989Z"
                                fill="#635BFF"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.772 12.6213C19.772 11.8813 20.3782 11.59 21.3859 11.59C22.8344 11.59 24.6608 12.0309 26.1094 12.8103V8.33869C24.527 7.70889 22.9682 7.46484 21.3937 7.46484C17.5283 7.46484 14.9619 9.4802 14.9619 12.8496C14.9619 18.1006 22.1967 17.2661 22.1967 19.5334C22.1967 20.4072 21.4331 20.6906 20.3703 20.6906C18.7879 20.6906 16.7726 20.0451 15.1745 19.1713V23.6979C16.9458 24.4616 18.7328 24.7843 20.3703 24.7843C24.3302 24.7843 27.0541 22.8241 27.0541 19.4153C27.0226 13.7471 19.772 14.7548 19.772 12.6213Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_f_11035_408433"
                                x="0.634727"
                                y="10.665"
                                width="39.9854"
                                height="29.086"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    stdDeviation="2.73"
                                    result="effect1_foregroundBlur_11035_408433"
                                />
                            </filter>
                            <clipPath id="clip0_11035_408433">
                                <rect
                                    width="31.49"
                                    height="31.49"
                                    fill="white"
                                    transform="translate(5.25488 0.379883)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </S.StripeSourceIcon>
                <S.StripeSourceTitle>Stripe</S.StripeSourceTitle>
            </S.StripeSourceHeader>
            <S.StripeSourceName>
                <img src="https://digest-file.s3.amazonaws.com/svgviewer-png-output.png" />{' '}
                Digest
            </S.StripeSourceName>
            <S.StripeSourceContainer>
                <SingleStripeBlock
                    name="Revenue"
                    value="$5,500"
                    time="Yesterday"
                />
                <SingleStripeBlock
                    name="Payments"
                    value="25"
                    time="Yesterday"
                />
                <SingleStripeBlock
                    name="Customers"
                    value="21"
                    time="Yesterday"
                />
            </S.StripeSourceContainer>
        </S.StripeSourceWrapper>
    );
};

export default StripeSource;
