import React, { useRef, MutableRefObject, createRef } from 'react';

//other components
import { Image, Button } from '../../components';
import { Link } from 'gatsby';

//styles and icons
import * as S from './CreateDigest.styled';
import LogoSVG from '../../assets/images/logo.svg';
import EditSVG from '../../assets/images/edit.svg';
import AddSVG from '../../assets/images/addIcon.svg';

//type  and constants
import HeaderI from './CreateDigest.type';
import SourcesList from '../../components/SourcesList/SourcesList';
import SourcesIcons from '../../components/SourcesIcons';

const APP_URL = process.env.GATSBY_REACT_APP_URL;

const CreateDigest = ({
    icons,
    sources,
    builderSources,
    addSource,
}: HeaderI) => {
    const placeholders = useRef<MutableRefObject<HTMLDivElement>[]>([]);

    placeholders.current = icons.map(
        (_, index) => placeholders.current[index] ?? createRef()
    );

    const displayProgressBars = () => {
        let progressBarsArray = [];
        let sourcesLength = sources.length;

        for (let i = 1; i < 4; i++) {
            progressBarsArray.push(
                sourcesLength >= i ? <S.StepItemActive /> : <S.StepItem />
            );
        }
        return progressBarsArray;
    };

    return (
        <S.CreateDigestWrapper>
            <S.CreateDigestHeader>
                <S.Logo>
                    <Link to={'/'}>
                        <Image
                            altText={'Digest-logo'}
                            localFile={{ publicURL: LogoSVG }}
                        />
                    </Link>
                </S.Logo>
                <S.StepsCreateDigest>
                    <S.Steps className="steps">
                        <S.StepsTitle>
                            Add at least <span>3 sources</span> to your Digest{' '}
                        </S.StepsTitle>
                        <S.CreationSteps>
                            {displayProgressBars()}
                        </S.CreationSteps>
                    </S.Steps>
                    <Button
                        disabled={sources.length > 0 ? false : true}
                        target="_blank"
                        onClick={() => {
                            if (window !== 'undefined') {
                                window.location.href = `${APP_URL}/signup?sources=${sources.map(
                                    (item) => item
                                )}`;
                            }
                        }}
                    >
                        Create
                    </Button>
                </S.StepsCreateDigest>
            </S.CreateDigestHeader>
            <S.CreateDigestSettings>
                <S.Steps className="steps-create-digest">
                    <S.StepsTitle>
                        Digest looks good with at least <span>3 blocks</span>
                    </S.StepsTitle>
                    <S.CreationSteps>{displayProgressBars()}</S.CreationSteps>
                </S.Steps>
                <S.Sources className="sources-create-digest">
                    <S.SourcesTitle>
                        Select sources
                        <p>Choose what you want to add to your digest</p>
                    </S.SourcesTitle>
                    <SourcesIcons
                        builderSources={builderSources}
                        addSource={addSource}
                    />
                </S.Sources>
                <S.AddSources>
                    <S.TitleWrapper>
                        <Image
                            altText={'Digest-edit-sources'}
                            localFile={{ publicURL: EditSVG }}
                        />
                        <S.EditTitle className="edit-title-one">
                            Your first digest
                        </S.EditTitle>
                        <S.EditTitle className="edit-title-two">
                            Your first digest
                        </S.EditTitle>
                    </S.TitleWrapper>
                    <SourcesList sources={sources} />
                    {sources.length < 1 ? (
                        <S.AddNewSource>
                            <Image
                                altText={'Digest-add-sources'}
                                localFile={{ publicURL: AddSVG }}
                            />
                            <S.AddSourceTitle>
                                Added source will be displayed here
                            </S.AddSourceTitle>
                        </S.AddNewSource>
                    ) : (
                        sources.length > 2 && (
                            <S.ButtonWrapper>
                                <Button
                                    disabled={sources.length > 0 ? false : true}
                                    target="_blank"
                                    onClick={() => {
                                        if (window !== 'undefined') {
                                            window.location.href = `${APP_URL}/signup?sources=${sources.map(
                                                (item) => item
                                            )}`;
                                        }
                                    }}
                                >
                                    Create
                                </Button>
                            </S.ButtonWrapper>
                        )
                    )}
                </S.AddSources>
            </S.CreateDigestSettings>
        </S.CreateDigestWrapper>
    );
};

export default CreateDigest;
