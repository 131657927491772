import styled from "styled-components";

export const DataSourceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 16px;
    border-radius: 8px;
    border: 1px solid #eaeceb;
    width: 100%;
    background: ${({ theme }) => theme.palette.white};
    margin-bottom:35px;
`;

export const DataSourceDayName = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #272828;
  margin-bottom: 8px;
`
export const DataSourceDayNumber = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 8px;

`

export const DataSourceDate = styled.div`
  font-weight: 500;
  color: #828584;
`