import * as Yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { SchemaLike } from 'yup/lib/types';

import { FormFieldNames } from './types';

const ErrorMessages = {
  RequiredField: 'This field is required',
  InvalidEmail: 'Email address is invalid',
};

export const composeFormSchema = <K extends FormFieldNames>(fields: K[]): AnyObjectSchema => {
  const schemaMap: Record<FormFieldNames, SchemaLike> = {
    email: Yup.string().required(ErrorMessages.RequiredField).email(ErrorMessages.InvalidEmail),
    name: Yup.string().required(ErrorMessages.RequiredField),
    phone: Yup.string().required(ErrorMessages.RequiredField),
    message: Yup.string(),
    agreement: Yup.boolean(),
    // password: Yup.string().required(ErrorMessages.RequiredField),
    // reason: Yup.string().required(ErrorMessages.RequiredField),
    // date: Yup.string().required(ErrorMessages.RequiredField),
    // period: Yup.number()
    //   .typeError('Must be a number')
    //   .integer('Must be an integer')
    //   .required(ErrorMessages.RequiredField),
  };

  const schema = fields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: schemaMap[field],
    }),
    {}
  );

  return Yup.object(schema);
};
