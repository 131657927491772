import React from 'react';
import * as S from './RSSSource.styled';
import RSSSourcePost from './RSSSourcePost';

const RSSSource = () => {
    return (
        <S.RSSSourceWrapper>
            <S.RSSSourceHeader>
                <S.RSSSourceIcon>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="41"
                        height="40"
                        viewBox="0 0 41 40"
                        fill="none"
                    >
                        <g opacity="0.25" filter="url(#filter0_f_8089_334601)">
                            <rect
                                x="6.09473"
                                y="16"
                                width="29.0655"
                                height="18.1659"
                                rx="9.08297"
                                fill="url(#paint0_linear_8089_334601)"
                            />
                        </g>
                        <rect
                            x="5"
                            width="32"
                            height="32"
                            rx="6"
                            fill="url(#paint1_linear_8089_334601)"
                        />
                        <path
                            d="M14.1191 15.4652C16.1083 15.4652 18.0159 16.2553 19.4224 17.6619C20.829 19.0684 21.6191 20.976 21.6191 22.9652M14.1191 9.63184C17.6554 9.63184 21.0467 11.0366 23.5472 13.5371C26.0477 16.0376 27.4525 19.4289 27.4525 22.9652M15.7858 22.1318C15.7858 22.5921 15.4127 22.9652 14.9525 22.9652C14.4922 22.9652 14.1191 22.5921 14.1191 22.1318C14.1191 21.6716 14.4922 21.2985 14.9525 21.2985C15.4127 21.2985 15.7858 21.6716 15.7858 22.1318Z"
                            stroke="white"
                            stroke-width="1.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <defs>
                            <filter
                                id="filter0_f_8089_334601"
                                x="0.634727"
                                y="10.54"
                                width="39.9854"
                                height="29.086"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="BackgroundImageFix"
                                    result="shape"
                                />
                                <feGaussianBlur
                                    stdDeviation="2.73"
                                    result="effect1_foregroundBlur_8089_334601"
                                />
                            </filter>
                            <linearGradient
                                id="paint0_linear_8089_334601"
                                x1="8.65473"
                                y1="17.3538"
                                x2="21.8126"
                                y2="40.118"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#CC6E00" />
                                <stop offset="0.0001" stop-color="#5865F2" />
                                <stop offset="1" stop-color="#FF8D4D" />
                                <stop offset="1" stop-color="#4D5CFF" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_8089_334601"
                                x1="23.2116"
                                y1="32.9543"
                                x2="8.25207"
                                y2="-6.85263e-07"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0.0729167" stop-color="#E17621" />
                                <stop offset="0.973958" stop-color="#FFB275" />
                            </linearGradient>
                        </defs>
                    </svg>
                </S.RSSSourceIcon>
                <S.RSSSourceTitle>TechCrunch</S.RSSSourceTitle>
            </S.RSSSourceHeader>
            <S.RSSSourceContainer>
                <RSSSourcePost
                    title="Hear how Sensi.AI is building AI for remote patient monitoring"
                    author="Kyle Wiggers"
                    date="May 18, 2023"
                />
                <RSSSourcePost
                    title="Turns out Ledger can hold some of your crypto wallet’s keys, if you agree to it"
                    author="Jacquelyn Melinek"
                    date="May 18, 2023"
                />
                <RSSSourcePost
                    title="Pudgy Penguins wants to use its NFT-inspired toys to bring IP to the real world"
                    author="Jacquelyn Melinek"
                    date="May 18, 2023"
                />
                <RSSSourcePost
                    title="Figuring it all out"
                    author="Brian Heater"
                    date="May 18, 2023"
                />
            </S.RSSSourceContainer>
        </S.RSSSourceWrapper>
    );
};

export default RSSSource;
