import styled from "styled-components";
import {typographyPreset14, typographyPreset18} from "../../../styles/typography";


export const CardBarProgress = styled.div`
  position:absolute;
  top:0;
  border-radius: 20.8372px;
  height:77px;
  width:8px;

`

export const SectionCardWrapper = styled.div<{isActive:boolean}>`
  max-width: 490px;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  background: white;
  height: 168px;
  border-radius: 12px;
  background:${({ isActive }) => isActive ? '#FCF8F2' : 'transition'};
  ${CardBarProgress}{
    background: ${({ isActive }) => isActive ? 'linear-gradient(180deg, #FCEABE 0%, #F6C035 100%)' : 'transition'}
  }

  @media (max-width: 992px) {
    max-width:100%;
  }
  
  @media (min-width: 992px) {
  :hover {
    background: #FCF8F2;
  }
}
`;

export const SectionCardInnerWrapper = styled.div`
  width: 100%;
  padding: 10px 16px 30px 32px;
  height: 136px;


  @media (max-width: 992px) {
    padding:16px;
    background:#FCF8F2;
    height:auto;
  }
`

export const Title = styled.h4`
  ${typographyPreset18};
  margin-bottom: 10px;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.palette.gray500};
  ${typographyPreset14};
`;

export const CardBar = styled.div`
  position:relative;
  width:8px;
  height:100%;
  background: #FCEABE;
  border-radius: 20.8372px;

  @media (max-width: 992px) {
    display:none;
  }
`

