import React from 'react';
import * as S from './TiktokSourcePost.styled';
import ITiktokSourcePost from './TiktokSourcePost.type';

const TiktokSourcePost = ({
    content,
    media,
    likes,
    shares,
    date,
    fullUsername,
    username,
    avatar,
}: ITiktokSourcePost) => {
    return (
        <S.TiktokSourcePost>
            <S.TiktokSourcePostWrapper>
                <S.TiktokSourcePostUser>
                    <S.TiktokSourcePostAvatar src={avatar} />
                    <div>
                        <S.TwitterNameBadgeWrapper>
                            <S.TiktokSourcePostName>
                                {fullUsername}
                            </S.TiktokSourcePostName>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 48 48"
                            >
                                <circle cx="24" cy="24" r="20" fill="#4dd0e1" />
                                <path
                                    fill="#fff"
                                    d="M22.491 30.69c-.576 0-1.152-.22-1.591-.659l-6.083-6.084a2.25 2.25 0 1 1 3.182-3.182l6.083 6.084a2.25 2.25 0 0 1-1.591 3.841z"
                                />
                                <path
                                    fill="#fff"
                                    d="M22.491 30.69a2.25 2.25 0 0 1-1.591-3.841l9.539-9.539a2.25 2.25 0 1 1 3.182 3.182l-9.539 9.539a2.244 2.244 0 0 1-1.591.659z"
                                />
                            </svg>
                        </S.TwitterNameBadgeWrapper>
                        <S.TiktokSourcePostUsername>
                            {username}
                        </S.TiktokSourcePostUsername>
                    </div>
                </S.TiktokSourcePostUser>

                <S.TiktokSourceImg src={media} width={300} height={300} />

                <S.TiktokSourcePostContent
                    dangerouslySetInnerHTML={{ __html: content }}
                />

                <S.TiktokSourcePostMeta>
                    <S.TiktokSourcePostItem>
                        <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.24725 0.794922C7.27987 0.794922 6.45325 1.48446 5.9991 1.95985C5.54494 1.48446 4.72017 0.794922 3.75325 0.794922C2.08663 0.794922 0.923096 1.95661 0.923096 3.61954C0.923096 5.45185 2.36817 6.63615 3.76617 7.78169C4.42617 8.32308 5.10925 8.88246 5.6331 9.50277C5.72125 9.60661 5.85048 9.66661 5.98617 9.66661H6.01294C6.1491 9.66661 6.27787 9.60615 6.36556 9.50277C6.89033 8.88246 7.57294 8.32261 8.2334 7.78169C9.63094 6.63661 11.0769 5.45231 11.0769 3.61954C11.0769 1.95661 9.9134 0.794922 8.24725 0.794922Z"
                                fill="#656766"
                            ></path>
                        </svg>
                        {likes}
                    </S.TiktokSourcePostItem>
                    <S.TiktokSourcePostItem>
                        <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.40625 11.4519L6.20944 9.82013C6.16125 9.82256 6.11287 9.82406 6.06394 9.82406C5.01319 9.82406 4.15837 9.15112 4.15837 8.32369V4.67963H5.65538L3.01519 1.07812L0.375 4.67963H1.87144V8.32369C1.87144 9.20569 2.30775 10.0341 3.09956 10.6579C3.89175 11.2817 4.94419 11.625 6.06394 11.625C6.52744 11.625 6.97931 11.5663 7.40625 11.4519ZM5.94206 2.17444C6.9915 2.17444 7.84556 2.84625 7.84556 3.67256V7.32469H6.35025L8.98781 10.9219L11.625 7.3245H10.1297V3.67256C10.1297 2.79169 9.69413 1.96406 8.90325 1.341C8.112 0.718312 7.0605 0.375 5.94206 0.375C5.4765 0.375 5.02256 0.435 4.59375 0.54975L5.78831 2.17856C5.83931 2.17575 5.8905 2.17444 5.94206 2.17444Z"
                                fill="#656766"
                            ></path>
                        </svg>
                        {shares}
                    </S.TiktokSourcePostItem>
                    <S.TiktokSourcePostItem>
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.74805 0.777588C2.74805 0.702931 2.71839 0.631332 2.6656 0.578541C2.61281 0.525751 2.54121 0.496094 2.46655 0.496094C2.3919 0.496094 2.3203 0.525751 2.26751 0.578541C2.21472 0.631332 2.18506 0.702931 2.18506 0.777588V1.05908H1.62207C1.32344 1.05908 1.03705 1.17771 0.825885 1.38887C0.614723 1.60003 0.496094 1.88643 0.496094 2.18506L0.496094 2.74805H9.50391V2.18506C9.50391 1.88643 9.38528 1.60003 9.17412 1.38887C8.96295 1.17771 8.67656 1.05908 8.37793 1.05908H7.81494V0.777588C7.81494 0.702931 7.78528 0.631332 7.73249 0.578541C7.6797 0.525751 7.6081 0.496094 7.53345 0.496094C7.45879 0.496094 7.38719 0.525751 7.3344 0.578541C7.28161 0.631332 7.25195 0.702931 7.25195 0.777588V1.05908H2.74805V0.777588ZM9.50391 8.37793V3.31104H0.496094V8.37793C0.496094 8.67656 0.614723 8.96295 0.825885 9.17412C1.03705 9.38528 1.32344 9.50391 1.62207 9.50391H8.37793C8.67656 9.50391 8.96295 9.38528 9.17412 9.17412C9.38528 8.96295 9.50391 8.67656 9.50391 8.37793Z"
                                fill="#656766"
                            ></path>
                        </svg>
                        {date}
                    </S.TiktokSourcePostItem>
                </S.TiktokSourcePostMeta>
            </S.TiktokSourcePostWrapper>
        </S.TiktokSourcePost>
    );
};

export default TiktokSourcePost;
