import React from 'react';

import * as S from './WeatherSourceBlock.styled';
import IWeatherSourceBlock from './WeatherSourceBlock.type';

const WeatherSourceBlock = ({
    day,
    date,
    humidity,
    tempDay,
    tempNight,
}: IWeatherSourceBlock) => {
    return (
        <S.WeatherSourceBlock>
            <S.WeatherSourceData>
                {day}, <S.WeatherSourceDataSpan>{date}</S.WeatherSourceDataSpan>
            </S.WeatherSourceData>
            <S.WeatherSourceForecastIcon>
                <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.8698 33.9199C12.5234 33.9199 9 30.2629 9 25.7517C9 21.2405 12.5234 17.5835 16.8698 17.5835C17.8123 17.5835 18.716 17.7554 19.5535 18.0707C21.2059 13.8757 25.1772 10.9199 29.8136 10.9199C35.9329 10.9199 40.8935 16.0686 40.8935 22.4199C40.8935 22.6997 40.8839 22.9772 40.8649 23.252C42.7154 24.1363 44 26.078 44 28.3311C44 31.4177 41.5892 33.9199 38.6154 33.9199H16.8698Z"
                        fill="#A7C8D6"
                    />
                    <path
                        d="M10.5444 40.9199C5.82545 40.9199 2 37.1039 2 32.3966C2 27.6892 5.82545 23.8732 10.5444 23.8732C11.5676 23.8732 12.5488 24.0526 13.4581 24.3816C15.2521 20.0042 19.5638 16.9199 24.5976 16.9199C31.2414 16.9199 36.6272 22.2925 36.6272 28.9199C36.6272 29.2119 36.6168 29.5014 36.5962 29.7881C38.6053 30.7109 40 32.7371 40 35.0881C40 38.3089 37.3826 40.9199 34.1538 40.9199H10.5444Z"
                        fill="#E3F4FB"
                    />
                </svg>
            </S.WeatherSourceForecastIcon>
            <S.WeatherSourceTemperatureContainer>
                <S.WeatherSourceTemperatureWrapper>
                    <S.WeatherSourceDayIcon>
                        <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_7521_287282)">
                                <path
                                    d="M8.29948 1.83301V3.16634M8.29948 13.833V15.1663M2.96615 8.49967H1.63281M4.50889 4.70909L3.56608 3.76628M12.0901 4.70909L13.0329 3.76628M4.50889 12.293L3.56608 13.2358M12.0901 12.293L13.0329 13.2358M14.9661 8.49967H13.6328M11.6328 8.49967C11.6328 10.3406 10.1404 11.833 8.29948 11.833C6.45853 11.833 4.96615 10.3406 4.96615 8.49967C4.96615 6.65873 6.45853 5.16634 8.29948 5.16634C10.1404 5.16634 11.6328 6.65873 11.6328 8.49967Z"
                                    stroke="#656766"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_7521_287282">
                                    <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                        transform="translate(0.300781 0.5)"
                                    ></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </S.WeatherSourceDayIcon>
                    {tempDay}
                </S.WeatherSourceTemperatureWrapper>
                <S.WeatherSourceTemperatureWrapper>
                    <S.WeatherSourceDayIcon>
                        <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.3008 10.8065C13.5127 11.1629 12.638 11.3613 11.7169 11.3613C8.24996 11.3613 5.43947 8.55083 5.43947 5.08392C5.43947 4.16281 5.63786 3.28804 5.99425 2.5C3.81624 3.48498 2.30078 5.67682 2.30078 8.22261C2.30078 11.6895 5.11126 14.5 8.57817 14.5C11.124 14.5 13.3158 12.9845 14.3008 10.8065Z"
                                stroke="#656766"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </S.WeatherSourceDayIcon>
                    {tempNight}
                </S.WeatherSourceTemperatureWrapper>
            </S.WeatherSourceTemperatureContainer>
            <S.WeatherSourceHumidity>
                <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.67411 4.05366C9.13262 3.32108 9.45765 2.51302 9.63411 1.66699C9.96745 3.33366 10.9674 4.93366 12.3008 6.00033C13.6341 7.06699 14.3008 8.33366 14.3008 9.66699C14.3046 10.5885 14.0347 11.4904 13.5253 12.2584C13.0159 13.0263 12.29 13.6258 11.4395 13.9807C10.5891 14.3355 9.65239 14.4299 8.74823 14.2518C7.84407 14.0738 7.01314 13.6312 6.36078 12.9803M4.96745 10.5203C6.43411 10.5203 7.63411 9.30032 7.63411 7.82032C7.63411 7.04699 7.25411 6.31366 6.49411 5.69366C5.73411 5.07366 5.16078 4.15366 4.96745 3.18699C4.77411 4.15366 4.20745 5.08033 3.44078 5.69366C2.67411 6.30699 2.30078 7.05366 2.30078 7.82032C2.30078 9.30032 3.50078 10.5203 4.96745 10.5203Z"
                        stroke="#717BBC"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                {humidity}
            </S.WeatherSourceHumidity>
        </S.WeatherSourceBlock>
    );
};

export default WeatherSourceBlock;
