import React from 'react';

//other components
import { Image, Navbar } from '../../components';
import { Link } from 'gatsby';

//styles and icons
import * as S from './Footer.styled';
import LogoSVG from '../../assets/images/logo.svg';

//type  and constants
import FooterI from './Footer.type';

const Footer = ({ menuItems }: FooterI) => {
    return (
        <S.FooterWrapper>
            <S.Logo>
                <Link to={'/'}>
                    <Image
                        altText={'Digest-logo'}
                        localFile={{ publicURL: LogoSVG }}
                    />
                </Link>
            </S.Logo>
            <Navbar menuItems={menuItems} />
            <S.Certificate>© 2023 Digest. All rights reserved.</S.Certificate>
        </S.FooterWrapper>
    );
};

export default Footer;
