import styled from 'styled-components';

export const InstagramSourceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    background: ${({ theme }) => theme.palette.white};
    margin-bottom: 35px;
`;

export const InstagramSourceHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-right: auto;
`;

export const InstagramSourceIcon = styled.div`
    svg {
        margin-right: 16px;
        margin-bottom: -12px;
    }
`;
export const InstagramSourceTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    text-align: left;
`;

export const InstagramSourceRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;
